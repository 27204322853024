import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 320px;
  margin-top: 80px;

  > div:first-child {
    min-height: calc(100vh - 400px);
  }
`

import React from "react";
import placeholder from "components/atoms/unavailable-placeholder/index.jpeg";
import { Wrapper, ImageWrapper, Image, TextWrapper, Text } from "./styles";

const ActivationProduct = ({ imageUrl, price, title }) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <Image
          src={imageUrl}
          onError={({ target }) => {
            if (target.src.endsWith('empty.png')) {
              return
            }
            target.src = placeholder
          }}
        />
      </ImageWrapper>
      <TextWrapper>
        <Text isPrice>{price}</Text>
        <Text>{title}</Text>
      </TextWrapper>
    </Wrapper>
  );
};

export default ActivationProduct;

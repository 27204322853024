import styled from 'styled-components';
import { rem } from 'polished';
import theme from 'lib/styles/theme';

export const Wrapper = styled.div`
`

export const ProductsWrapper = styled.div`
  width: 100%;
  max-width: 986px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 222px);
  grid-gap: 32px;
`

export const EmptyProducts = styled.div`
  height: calc(100vh - 642px);
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 10px;
  background-color: #FAF9F9;
  border: 1.5px solid #EDEDED;
  font-size: ${rem(18)};

  > p {
    color: #838383;
    text-align: center;
    line-height: 41px;

    > a {
      color: ${theme.pink.dark};
      text-decoration: underline;
      display: inline;
    }
  }

  > :nth-child(1) {
    display: grid;
    align-items: end;
  }
`

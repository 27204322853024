import styled from 'styled-components';
import { rem } from 'polished';

export const Wrapper = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 192px 220px 220px 1fr 194px;
  align-items: center;
  position: relative;

  > .hovered-activation {
    display: none;
  }

  &:hover {
    > .hovered-activation {
      display: grid;
    }
  }
`

export const HoweredColumn = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(250, 249, 249, .5);
`

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
`

export const Button = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 150px;
  background-color: #FFFFFF;
  border: 1px solid #A0A0A0;
  border-radius: 19px;
  cursor: pointer;
  color: #000;
  text-transform: uppercase;
  font-size: ${rem(12)};
  position: relative;
  padding-left: 15px;

  > svg {
    position: absolute;
  }
`

export const Column = styled.div`
  display: grid;
  align-items: center;
  padding-left: 20px;
  height: 100%;
  width: 100%;
`

export const Image = styled.img`
  height: 120px;
  width: 172px;
  object-fit: contain;
`

export const TitleWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 90px;
`

export const BrandName = styled.div`
  color: #9B9B9B;
  text-transform: uppercase;
  font-size: ${rem(16)};
  display: grid;
  align-items: end;
`

export const Title = styled.div`
  color: #000;
  font-size: ${rem(14)};
  padding-top: 10px;
`

export const Description = styled.div`
  color: #000;
  font-size: ${rem(12)};
`

export const ProductsWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 30px 270px 1fr;
  align-items: center;
  color: #F77294;
`

export const ProductsCount = styled.div`
  font-size: ${rem(12)};
`

export const ProductsImages = styled.div`
`

export const ProductsImage = styled.img`
  height: 39px;
  width: 39px;
  margin-right: 5px;
`

export const ProductsArrow = styled.div`
  font-size: ${rem(16)};
`

export const StatusWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 20px 1fr;
`

export const Status = styled.div`
  ${props => props.status === 'visible' ? 'color: #f77294;' : ''}
  ${props => props.status === 'hidden' ? 'color: #e2934a;' : ''}
  font-size: ${rem(14)};
  font-weight: bold;
  text-transform: uppercase;
  display: grid;
  align-items: end;
  padding-bottom: 5px;
`

export const Date = styled.div`
  color: #000000;
  font-size: ${rem(12)};
  text-transform: uppercase;
`
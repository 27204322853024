import { types } from 'mobx-state-tree';
import { Product } from 'data-access/stores/models/product';

export const Activation = types.model('ActivationModel', {
  id: types.string,
  imageUrl: types.string,
  brandName: types.string,
  title: types.string,
  description: types.string,
  products: types.array(Product),
  status: types.string,
  startDateTime: types.Date,
  endDateTime: types.Date,
  mediaType: types.string,
  unavailableProductsCount: types.number
})

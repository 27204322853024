import React from "react";
import Button from "components/atoms/button";
import placeholder from "components/atoms/unavailable-placeholder/index.jpeg";
import {
  Wrapper,
  Column,
  BrandWrapper,
  Image,
  Name,
  VisibleActivations,
  Remove,
  AllAllowed
} from "./styles";

// function for render every *my* brand in a table for the brands page
const Brand = ({
  imageUrl,
  name,
  visibleActivations,
  accessLevel,
  allowAllActivations,
  removeBrand,
}) => {
  return (
    <Wrapper>
      <Column>
        <BrandWrapper>
          <Image
            src={imageUrl}
            onError={({ target }) => (target.src = placeholder)}
          />
          <Name>{name}</Name>
        </BrandWrapper>
      </Column>
      <Column>
        <VisibleActivations count={visibleActivations}>
          {visibleActivations}
        </VisibleActivations>
      </Column>
      <Column>
        {accessLevel === "all" ? (
          <AllAllowed>All Activations Allowed</AllAllowed>
        ) : (
          <Button
            text={"Allow all activations"}
            clickAction={allowAllActivations}
            styles={{ height: "max-content", width: 200, fontSize: 15 }}
          />
        )}
      </Column>
      <Column>
        <Remove onClick={removeBrand}>remove</Remove>
      </Column>
    </Wrapper>
  );
};

export default Brand;

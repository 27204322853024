import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import Loader from 'components/atoms/loader';
import { Wrapper } from './styles';

class Callback extends Component {
  async componentDidMount() {
    const { history } = this.props
    const { authStore } = this.props.store
    await authStore.handleAuthResult();
    history.replace('/brands');
  }

  render() {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }
}

export default withRouter(inject('store')(observer(Callback)))

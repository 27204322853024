import React from 'react';

const Chain = ({ fill, height, width, styles }) => (
  <svg style={styles} width={width} height={height} viewBox={"0 0 " + width + " " + height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.701637">
      <path d="M69.3434 19.0241H89.9164" stroke={fill} strokeWidth="0.95472" strokeLinecap="square" strokeDasharray="2.86 2.86"/>
      <path d="M0.836275 19.0241H21.4093" stroke={fill} strokeWidth="0.95472" strokeLinecap="square" strokeDasharray="2.86 2.86"/>
      <path d="M38.0605 31.2768C35.6183 31.2753 33.4172 29.8494 32.4822 27.6631C31.5473 25.4768 32.0624 22.9601 33.7876 21.2851L39.2777 15.9609C40.4093 14.8605 41.9474 14.2432 43.5506 14.2461C44.7056 14.2461 45.8365 14.5668 46.8092 15.1704C47.1765 15.3967 47.5166 15.6618 47.8235 15.9609C48.1734 16.3008 48.3557 16.77 48.3241 17.2491C48.2944 17.65 48.1166 18.0271 47.8235 18.3114C47.1421 18.9328 46.0792 18.9328 45.3978 18.3114C44.3868 17.3329 42.7489 17.3329 41.7378 18.3114L36.2262 23.6481C35.2143 24.6287 35.2143 26.2184 36.2262 27.199C37.2381 28.1795 38.8787 28.1795 39.8905 27.199L43.1492 24.0371C43.2723 23.917 43.458 23.8807 43.6196 23.9451C44.4967 24.2908 45.4351 24.4669 46.3819 24.4637H46.5545C46.7299 24.4627 46.8884 24.5646 46.9557 24.7216C47.0229 24.8785 46.9855 25.0592 46.861 25.1789L42.3291 29.5704C41.1979 30.6679 39.6613 31.2822 38.0605 31.2768Z" fill={fill}/>
      <path d="M45.4548 23.7075H45.4289H45.127C44.9118 23.6992 44.6972 23.6796 44.4842 23.649C44.3073 23.6197 44.1348 23.5862 43.9622 23.5444L43.7077 23.4775C43.6171 23.4524 43.5222 23.4231 43.4316 23.3897C43.341 23.3562 43.2461 23.3269 43.1555 23.2893C43.0649 23.2517 42.9744 23.2182 42.8838 23.1764C42.2417 22.8879 41.6575 22.4915 41.1582 22.0053C40.8085 21.6654 40.6262 21.1963 40.6578 20.7172C40.6855 20.3133 40.8633 19.9329 41.1582 19.6465C41.8393 19.025 42.9016 19.025 43.5826 19.6465C44.5932 20.625 46.2302 20.625 47.2408 19.6465L48.6557 18.283L48.6902 18.2454L52.7366 14.3265C53.748 13.346 53.748 11.7563 52.7366 10.7757C51.7253 9.79522 50.0855 9.79522 49.0742 10.7757L45.8258 13.9209C45.7027 14.041 45.5171 14.0773 45.3556 14.0129C44.4807 13.666 43.5443 13.4884 42.5991 13.4901H42.4394C42.2632 13.4933 42.1026 13.3923 42.0339 13.235C41.9644 13.0781 42.0003 12.8959 42.1245 12.7749L46.6498 8.39181C49.0084 6.10507 52.8326 6.10507 55.1912 8.39181C57.5499 10.6786 57.5499 14.3861 55.1912 16.6728L49.6997 21.997C48.9272 22.7461 47.9596 23.278 46.9 23.5361H46.8525L46.6023 23.5862L46.4643 23.6113L46.1666 23.6532H46.0199C45.9078 23.6532 45.8086 23.6741 45.7137 23.6783L45.4548 23.7075Z" fill={fill}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M44.9705 37.9C55.3003 37.9 63.6743 29.6397 63.6743 19.45C63.6743 9.26035 55.3003 1 44.9705 1C34.6406 1 26.2666 9.26035 26.2666 19.45C26.2666 29.6397 34.6406 37.9 44.9705 37.9Z" stroke={fill} strokeWidth="0.95472"/>
    </g>
  </svg>
);

Chain.defaultProps = {
  fill: '#979797',
  height: '39',
  width: '91',
  styles: {}
};

export default Chain;

import styled from 'styled-components';
import { rem } from 'polished';
import theme from 'lib/styles/theme';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 50px;
  display: grid;
  grid-template-columns: 1fr repeat(6, 30px);
  align-items: center;
  justify-content: right;
`

export const NewWrapper = styled.div`
  width: 100%;
  padding: 50px;
  display: grid;
  grid-template-columns: 1fr repeat(6, 30px);
  align-items: center;
  justify-content: right;
`

export const Link = styled.div`
  display: inline-block;
  margin: auto 10px;
  font-size: ${rem(14)};
  color: ${props => props.disabled ? theme.black : "#F77294"};
  ${props => props.disabled ? "" : "cursor: pointer;"}
  font-family: "Helvetica-Bold";
`

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Brand from 'components/molecules/brand';
import { NewPaginator } from 'components/molecules/paginator';
import Modal, { buildModal } from 'components/atoms/modal';
import Ok from 'components/atoms/icons/ok';
import Loader from 'components/atoms/loader';
import {
  Wrapper,
  PageSection,
  PageSectionTitle,
  PageSectionButton,
  SortSection,
  SortColumn,
  EmptyBrands,
  Table,
  Row,
  InfoMessage,
  MessageWrapper
} from './styles';

// class for render main component (wo top and bottom navs) in the brands page
class Brands extends Component {
  state = {
    isAscSorting: true,
    myBrandToDelete: null,
    isDeleted: false,
    page: 0,
    pageSize: 10,
    continuationTokens: []
  }
  
  async updateInfo(state) {
    const { page, pageSize, isAscSorting, continuationTokens } = state
    let params = `?pageLimit=${pageSize}`
    if (isAscSorting) {
      params += `&sort=asc`
    } else {
      params += `&sort=desc`
    }
    const continuationTokenIndex = page - 1
    const continuationToken = continuationTokens[continuationTokenIndex]
    const { fetchMyBrands } = this.props.store.brandsStore
    const newContinuationToken = await fetchMyBrands(params, continuationToken)
    if (newContinuationToken !== undefined) {
      this.setState({
        continuationTokens: [...continuationTokens.slice(0, page), newContinuationToken]
      })
    }
  }

  async componentDidMount() {
    await this.updateInfo(this.state)
  }

  async sortMyBrands() {
    this.setState(prevState => {
      return {
        isAscSorting: !prevState.isAscSorting,
        page: 0,
        continuationTokens: []
      }
    }, function() {
      this.updateInfo(this.state)
    })
  }

  async allowAllActivations(myBrand) {
    const { changeAccessLevel } = this.props.store.brandsStore
    await changeAccessLevel(myBrand.id)
    await this.updateInfo(this.state)
  }

  removeMyBrand = myBrand => {
    this.setState({
      myBrandToDelete: myBrand
    })
  }

  async confirmRemoveMyBrand() {
    const { myBrandToDelete } = this.state
    await myBrandToDelete.remove()
    this.setState({
      myBrandToDelete: null,
      isDeleted: true,
      page: 0,
      continuationTokens: []
    }, function() {
      this.updateInfo(this.state)
    })
  }

  cancelRemoveMyBrand = () => {
    this.setState({
      myBrandToDelete: null,
      isDeleted: false
    })
  }

  changePage = delta => {
    this.setState(prevState => {
      return {
        page: prevState.page + delta
      }
    }, function() {
      this.updateInfo(this.state)
    })
  }

  render() {
    const { isNewBrands, isLoading, myBrands } = this.props.store.brandsStore
    const { myBrandToDelete, isDeleted, page, continuationTokens } = this.state

    const hasNext = continuationTokens[page] !== undefined ? true : false
    const hasPrev = page > 0 ? true : false
    
    const modal = buildModal(
      'remove brand',
      [
        'Are you sure you’d like to remove the following brand(s)?',
        myBrandToDelete ? myBrandToDelete.name : '',
        'Doing so will remove all stories, products and related content.',
      ],
      'yes, remove and delete all related content',
      () => this.confirmRemoveMyBrand(),
      'cancel',
      () => this.cancelRemoveMyBrand()
    )

    let emptyHeightOffsetCount = 0
    emptyHeightOffsetCount = isNewBrands && myBrands.length !== 0 ? emptyHeightOffsetCount + 1 : emptyHeightOffsetCount
    emptyHeightOffsetCount = isDeleted ? emptyHeightOffsetCount + 1 : emptyHeightOffsetCount

    return (
      <Wrapper>
        <Modal isOpen={myBrandToDelete ? true : false} component={modal} />

        <PageSection>
          <PageSectionTitle>active brands</PageSectionTitle>
          <PageSectionButton>
            <NavLink to='/add-brands'>
              <p className="plus">+</p>
              <p>add brand</p>
            </NavLink>
          </PageSectionButton>
        </PageSection>

        {isNewBrands && myBrands.length !== 0 ?
          <InfoMessage>
            New brands available! <NavLink to='/add-brands'>Add brands</NavLink> to start using their products or content.
          </InfoMessage> :
          ''
        }

        {isDeleted ?
          <InfoMessage>
            <MessageWrapper>
              <Ok />
              Brand successfully removed.
            </MessageWrapper>
          </InfoMessage> :
          ''
        }

        {
          isLoading
            ? <Loader styles={{height: "calc(100% - 100px)"}}/>
            : myBrands.length === 0
              ? (
                <EmptyBrands style={{ height: `calc(100% - 100px - calc(80px * ${emptyHeightOffsetCount}))`}}>
                  <p>You currently have no brands added.</p>
                  <p><NavLink to='/add-brands'>ADD BRANDS</NavLink> to get started.</p>
                </EmptyBrands>
              )
              : (
                <>
                  <SortSection>
                    <SortColumn isArrow={true} onClick={() => this.sortMyBrands()}>
                      brand
                    </SortColumn>
                    <SortColumn isCentered={true} isArrow={true}>
                      visible activations
                    </SortColumn>
                    <SortColumn isArrow={true}>
                      access level
                    </SortColumn>
                  </SortSection>

                  <Table>
                    {myBrands.map(myBrand => (
                      <Row key={myBrand.id}>
                        <Brand
                          key={myBrand.id}
                          imageUrl={myBrand.imageUrl}
                          name={myBrand.name}
                          visibleActivations={myBrand.visibleActivations}
                          accessLevel={myBrand.accessLevel}
                          allowAllActivations={() => this.allowAllActivations(myBrand)}
                          removeBrand={() => this.removeMyBrand(myBrand)}
                        />
                      </Row>
                    )
                    )}
                  </Table>

                  <NewPaginator
                    page={page}
                    hasNext={hasNext}
                    hasPrev={hasPrev}
                    changePage={(delta) => this.changePage(delta)}
                  />
                </>
              )
        }
      </Wrapper>
    )
  }
}

export default inject('store')(observer(Brands))

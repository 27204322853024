import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { DropZone, Thumbnail } from "@shopify/polaris"
import Loader from 'components/atoms/loader';
import { DropZoneWrapper, ThumbnailWrapper } from "./styles";

class AvatarUploader extends Component {
  state = {
    isLoading: false
  }

  handleDropDropZone = async (_dropFiles, acceptedFiles, _rejectedFiles) => {
    this.setState({ isLoading: true }, async () => {
      await this.props.store.authStore.uploadAvatar(acceptedFiles[0])
      this.setState({ isLoading: false })
    })
  }

  render() {
    const { isLoading } = this.state
    const { avatarUrl } = this.props.store.authStore.user;

    return (
      <DropZoneWrapper>
        <DropZone
          allowMultiple={false}
          onDrop={this.handleDropDropZone}
          disabled={isLoading}
        >
          {
            isLoading
              ? <Loader />
              : (
                <>
                  { avatarUrl && (
                    <ThumbnailWrapper>
                      <Thumbnail source={avatarUrl} /> 
                    </ThumbnailWrapper>
                  ) }
                  { !avatarUrl && (
                    <DropZone.FileUpload
                      actionTitle="Add image"
                      actionHint="or drop images to upload"
                    />
                  ) }
                </>
              )
          }
        </DropZone>
      </DropZoneWrapper>
    )
  }
}

export default inject("store")(observer(AvatarUploader));

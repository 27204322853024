import styled from 'styled-components';
import { rem } from 'polished';
import theme from 'lib/styles/theme';

export const Wrapper = styled.div`
`

export const TitlesSection = styled.div`
  height: 30px;
  width: 100%;
  display: grid;
  grid-template-columns: 192px 220px 220px 1fr 194px;
  align-items: end;
`

export const Title = styled.div`
  color: ${theme.black};
  text-transform: uppercase;
  font-size: ${rem(12)};
  padding-left: 20px;
`

export const EmptyActivations = styled.div`
  min-height: calc(100vh - 672px);
  width: 100%;

  color: #838383;
  font-size: ${rem(18)};
  display: grid;
  align-items: center;
  justify-content: center;

  border: 1.5px solid #EDEDED;
  background-color: #FAF9F9;
`

export const Table = styled.div``

export const Row = styled.div`
  height: 140px;
  ${props => props.status === 'hidden' ? 'background-color: #faf9f9;' : ''}
  border-top: 1.5px solid #ededed;

  &:last-of-type {
    border-bottom: 1.5px solid #ededed;
  }
`

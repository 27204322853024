import React from "react";

const Logo = () => (
  <svg width="61" height="42" viewBox="0 0 61 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.96288 41.9539C7.62601 41.5367 7.18298 40.988 7.18298 39.4214H7.14894V2.57864C7.14894 1.01201 7.59196 0.463295 7.92884 0.0460561C7.94137 0.0305374 7.95375 0.0152005 7.96596 0H0C0.0263194 0.0364132 0.054146 0.0736084 0.0830267 0.112213C0.390346 0.522999 0.817021 1.09333 0.817021 2.57864V39.4214C0.817021 40.9067 0.390346 41.477 0.0830264 41.8878C0.0541458 41.9264 0.0263193 41.9636 0 42H8C7.98779 41.9848 7.97541 41.9695 7.96288 41.9539ZM35 21.0176C35 7.61713 26.0584 1.37531 20 0C24.1606 2.46851 27.3723 10.0856 27.3723 21.0176C27.3723 30.1511 25.4745 38.2267 20.073 42C26.1314 40.6247 35 34.4887 35 21.0176Z" fill="#FFF"/>
    <path d="M59.75 38.1875V5.0625" stroke="#FFF" strokeWidth="1.5" strokeLinecap="square"/>
  </svg>
)

export default Logo

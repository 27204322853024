import { types } from 'mobx-state-tree';

export const User = types.model('UserModel', {
  id: '',
  email: '',
  companyWebsite: '',
  companyName: '',
  contactName: '',
  phone: '',
  avatarUrl: ''
})

import { types } from 'mobx-state-tree';

export const Content = types.model('ContentModel', {
  id: types.string,
  imageUrl: types.string,
  brandName: types.string,
  title: types.string,
  description: types.string,
  scheduleId: types.string,
  startDateTime: types.Date,
  endDateTime: types.Date
})

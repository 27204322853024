import styled from 'styled-components';
import { rem } from 'polished';

export const Wrapper = styled.div`
  width: 100%;
`

export const ImageWrapper = styled.div`
  height: 177px;
  width: 100%;
  background-color: #FFFFFF;
  border: 1.5px solid #E5EAF1;
  display: grid;
  align-items: center;
  justify-content: center;
`

export const Hovered = styled.div`
  height: 177px;
  width: 222px;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  display: grid;
  align-items: center;
  justify-content: center;
`

export const Button = styled.div`
  height: 38px;
  width: 170px;
  background-color: #fff;
  border: 1px solid #A0A0A0;
  border-radius: 19px;
  color: #000000;
  text-transform: uppercase;
  font-weight: bold;
  font-size: ${rem(12)};
  display: grid;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-top: 3px;
  cursor: pointer;
  position: relative;

  > svg {
    position: absolute;
    left: 10px;
  }
`

export const Image = styled.img`
  max-height: 174px;
  max-width: 219px;
`

export const TextsWrapper = styled.div`
  width: 100%;
  margin-top: 12px;
  color: #000;
  font-size: ${rem(12)};
`

export const Price = styled.div`
  color: #0288C4;
`
export const Title = styled.div``

export const Brand = styled.div`
  text-transform: uppercase;
`

export const EndDate = styled.div`
  margin-top: 12px;
  text-transform: uppercase;
`

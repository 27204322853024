import React from 'react';

const AddedBrand = ({ fill, stroke, height, width, styles }) => (
  <svg style={styles} width={width} height={height} viewBox={"0 0 " + width + " " + height} fill="none" xmlns="http://www.w3.org/2000/svg">  
    <rect width={width} height={height} rx="12.5" fill={fill}/>
    <path d="M10.7144 16.1083L18.5009 8.75" stroke={stroke} strokeWidth="2" strokeLinecap="square"/>
    <path d="M6.54762 12.5312L10.4652 16.2333" stroke={stroke} strokeWidth="2" strokeLinecap="square"/>
  </svg>  
);

AddedBrand.defaultProps = {
  fill: '#FF7293',
  stroke: '#F5F6F9',
  height: '25',
  width: '25',
  styles: {}
};

export default AddedBrand;

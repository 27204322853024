import styled from "styled-components";

export const PageWrapper = styled.div`
  padding: 30px 0 0;

  .Polaris-Layout__Section {
    @media (min-width: 984px) {
      padding: 0 15px;
    }
  }
`

import styled from 'styled-components';
import { rem } from 'polished';

export const Wrapper = styled.div`
  display: grid;
  width: calc(100% - 35px - 107px);
  margin: auto;
  margin-left: 107px;
  grid-template-columns: 1fr 166px 286px 50px;
  grid-column-gap: 90px;
  align-items: center;
  height: 100%;
`

export const Column = styled.div`
  display: grid;
  align-items: center;
`

export const BrandWrapper = styled.div`
  display: grid;
  grid-template-columns: 51px 1fr;
  align-items: center;
  grid-column-gap: 26px;
`

export const Image = styled.img`
  height: 51px;
  width: 51px;
  object-fit: contain;
`

export const Name = styled.div`
  color: #E55879;
  font-size: ${rem(14)};
  font-family: "Helvetica-Bold";
`

export const VisibleActivations = styled.div`
  display: grid;
  justify-content: center;
  font-size: ${rem(14)};
  font-family: "Helvetica-Bold";
  color: ${props => props.count > 0 ? "#61ae08" : "#000000"};
  margin: auto;
`

export const Remove = styled.div`
  font-size: ${rem(11)};
  font-family: "Helvetica-Bold";
  text-transform: uppercase;
  color: #9B9B9B;
  cursor: pointer;
`

export const AllAllowed = styled.p`
  font-size: ${rem(14)};
`
import { types, getEnv, flow, destroy } from 'mobx-state-tree';
import { User } from 'data-access/stores/models/user'

export const AuthStore = types.model('AuthStore', {
  user: types.maybeNull(User),
  token: '',
  isLoading: false
})
.actions(self => ({
  fetchUser: flow(function* fetchUser() {
    try {
      self.isLoading = true
      const { data } = yield getEnv(self).apiClient.get(
        '/brand/v1/brands',
        {headers: {Authorization: `Bearer ${self.token}`}}
      )
      self.user = {
        id: data.id,
        email: data.email,
        companyWebsite: data.domainName,
        companyName: data.company,
        contactName: data.fullName,
        phone: data.phone,
        avatarUrl: data.logoUrl
      }
      self.isLoading = false
    } catch (e) {
      console.error('fetchUser', e)
    }
  }),
  deleteAvatar: () => {
    self.user.avatarUrl = ''
  },
  updateUser: flow(function* updateUser(updates) {
    try {
      self.isLoading = true
      yield getEnv(self).apiClient.put('/brand/v1/brands', updates)
      yield self.fetchUser()
      self.isLoading = false
    } catch (e) {
      console.error('updateUser', e)
    }
  }),
  uploadAvatar: flow(function* uploadAvatar(file) {
    try {
      self.isLoading = true
      const { data } = yield getEnv(self).apiClient.post(
        '/content/v1/contents/uploadContent',
        {mediaType: file.type, filename: file.name}
      )
      yield getEnv(self).apiClient.uploadMedia(data.url, file)
      yield self.updateUser({logoUrl: data.contentFileId})
      self.isLoading = false
    } catch (e) {
      console.error('uploadAvatar', e)
    }
  }),
  handleAuthResult: flow(function* handleAuthResult() {
    try {
      const { idToken } = yield getEnv(self).authService.handleAuthentication()
      self.token = idToken
      yield self.fetchUser()
      return true;
    } catch (e) {
      console.error('handleAuthResult', e);
    }
  }),
  logout() {
    destroy(self.user);
    getEnv(self).authService.logOut();
  },
}))
.views(self => ({
  isAuthed() {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }
}))
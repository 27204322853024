import { types, flow, getEnv } from 'mobx-state-tree';
import { Activation } from 'data-access/stores/models/activation';
import { mapProduct } from 'data-access/stores/product';

export const ActivationsStore = types.model('ActivationsStore', {
  activations: types.array(Activation),
  currentActivation: types.maybeNull(Activation),
  isLoading: false
})
.actions(self => ({
  fetchActivations: flow(function* fetchActivations(requestParams, requestContinuationToken) {
    self.isLoading = true
    self.activations = []

    const url = 'syndication/v1/schedules'
    let params = requestParams
    if (requestContinuationToken !== undefined) {
      params = `${requestParams}&continuationToken=${requestContinuationToken}`
    }

    let data
    try {
      data = yield getEnv(self).apiClient.get(url + params)
    } catch(e) {
      console.log(e)
      self.isLoading = false
      return undefined
    }
    const { continuationToken, schedules } = data.data

    self.activations = schedules.map(activation => {
      return {
        id: activation.scheduleId,
        imageUrl: activation.mediaUrls.length ? activation.mediaUrls[0] : 'empty.png',
        brandName: activation.brandName,
        title: activation.title,
        description: '',
        products: activation.products && activation.products.map(product => mapProduct(product, activation.brandName)),
        status: activation.status,
        startDateTime: new Date(activation.startDate),
        endDateTime: new Date(activation.endDate),
        mediaType: 'image',
        unavailableProductsCount: (typeof activation.unavailableProductsCount == 'number' && activation.unavailableProductsCount) || 0
      }
    })

    let newContinuationToken = undefined
    if (continuationToken !== undefined) {
      params = `${requestParams}&continuationToken=${continuationToken}`
      let { data } = yield getEnv(self).apiClient.get(url + params)
      const nextContinuationToken = data.continuationToken
      const nextActivations = data.schedules
      if (nextContinuationToken !== undefined && nextActivations.length > 0) {
        newContinuationToken = continuationToken
      }
    }

    console.log(self.activations.slice())

    self.isLoading = false
    return newContinuationToken
  }),
  fetchCurrentActivation: flow(function* fetchCurrentActivation(id) {
    const url = 'syndication/v1/schedules'
    self.isLoading = true
    self.currentActivation = null
    try {
      const { data } = yield getEnv(self).apiClient.get(`${url}/${id}`)
      self.currentActivation = {
        id: data.scheduleId,
        imageUrl: data.mediaUrls.length ? data.mediaUrls[0] : 'empty.png',
        brandName: data.brandName,
        title: data.title,
        description: '',
        products: data.products && data.products.map(product => mapProduct(product, data.brandName)),
        status: data.status,
        startDateTime: new Date(data.startDate),
        endDateTime: new Date(data.endDate),
        mediaType: 'image'
      }
    } catch (e) {
      console.log(e)
      self.currentActivation = null
    }
    self.isLoading = false
  }),
  changeStatus: flow(function* changeStatus(activationId) {
    self.isLoading = true
    try {
      yield getEnv(self).apiClient.put('syndication/v1/schedules/' + activationId + '/visibility')
    } catch (e) {
      console.log(e)
    }
    self.isLoading = false
  }),
}))

import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from 'components/atoms/icons/logo';
import UserDropdown from 'components/molecules/user-dropdown';
import { Wrapper, LogoWrapper, NavLinksWrapper } from './styles';

// function for render top navigation on every page (logo, links and userdropdown)
const TopNav = () => (
  <Wrapper>
    <LogoWrapper>
      <Logo />
      <p>retailer<br />dashboard</p>
    </LogoWrapper>
    <NavLinksWrapper>
      <NavLink to='/brands'>
        brands
      </NavLink>
      <NavLink to='/my-api'>
        my activations
      </NavLink>
      <a href={process.env.REACT_APP_BRAND_DASH_URL}>
        switch to brand dashboard
      </a>
    </NavLinksWrapper>
    <UserDropdown />
  </Wrapper>
)

export default TopNav

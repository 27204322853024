import styled from 'styled-components';
import { IoIosArrowDown } from 'react-icons/io';
import theme from 'lib/styles/theme';

const menuWidth = '300px'

export const UserDropdownContainer = styled.div`
  width: ${menuWidth};
  height: 100%;
  background-color: #e06282;
  padding: 0 30px;
  cursor: pointer;
`

export const DropdownSelector = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 32px 1fr 32px;
  grid-column-gap: 15px;

  > h3 {
    font-size: 14px;
    color: ${theme.white};
    text-transform: uppercase;
    font-family: "Helvetica-Bold";
  }

  > svg {
    transform: rotateX(${({ pointsUp }) => (pointsUp ? `180deg` : `0deg`)});
    transition: transform 0.2s ease;
  }
`

export const CaretIcon = styled(IoIosArrowDown)``

export const UserDropdownMenu = styled.div`
  width: ${menuWidth};
  min-height: 150px;
  position: absolute;
  top: 80px;
  right: 0;
  padding: 20px 46px;
  background-color: #e06282;

  > a, div {
    width: 100%;
    display: block;
    color: ${theme.white};
    margin-bottom: 20px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
  }
`

import styled from 'styled-components';
import { rem } from 'polished';

export const Wrapper = styled.div`
  height: 478px;
  width: 300px;
  background: #FAF9F9;
  border: 1.35px solid #EDEDED;
  padding: 18px;
  position: relative;
`

export const ImageWrapper = styled.div`
  height: 185px;
  width: 264px;
  display: grid;
  align-items: center;
  justify-content: center;
`

export const Image = styled.img`
  max-height: 185px;
  max-width: 264px;
`

export const TextsWrapper = styled.div`
  height: calc(100% - 192px);
  width: 100%;
  margin-top: 7px;
  color: #000;
  font-size: ${rem(12)};
  display: grid;
  grid-template-rows: repeat(2, 25px) 1fr repeat(2, 20px);
`

export const Brand = styled.div`
  text-transform: uppercase;
  color: #D14E6F;
  font-size: ${rem(14)};
  font-weight: bold;
  display: grid;
  align-items: center;
`

export const Title = styled.div`
  font-size: ${rem(14)};
  font-weight: bold;
  display: grid;
  align-items: center;
`

export const Description = styled.div`
  font-size: ${rem(14)};
  display: grid;
  align-items: center;
`

export const Date = styled.div`
  font-size: ${rem(10)};
  display: grid;
  align-items: center;
  text-transform: uppercase;
`

export const Hovered = styled.div`
  height: 478px;
  width: 300px;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  align-items: center;
  justify-content: center;
`

export const Button = styled.div`
  height: 38px;
  width: 170px;
  background-color: #fff;
  border: 1px solid #A0A0A0;
  border-radius: 19px;
  color: #000000;
  text-transform: uppercase;
  font-weight: bold;
  font-size: ${rem(12)};
  display: grid;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-top: 3px;
  cursor: pointer;
  position: relative;

  > svg {
    position: absolute;
    left: 10px;
  }
`
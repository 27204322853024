import styled from 'styled-components';
import { rem } from 'polished';
import theme from 'lib/styles/theme';

export const Wrapper = styled.div`
`

export const PageSection = styled.div`
  height: 116px;
  background-color: #F7E4E4;
  padding: 50px 100px;

  > a {
    font-size: ${rem(17)};
    text-decoration: none;
    text-transform: uppercase;
    margin-right: 50px;
    color: #F77294;
    position: relative;

    &.active {
      color: ${theme.black};
      &:before {
        position: absolute;
        left: 0;
        bottom: -5px;
        content: '';
        width: 100%;
        height: 2px;
        background-color: ${theme.black};
      }
    }
  }
`

export const InputsWrapper = styled.div`
  padding: 30px 0px;
  height: 126px;
  width: ${props => props.isAllActivations ? '100%' : '986px'};
  ${props => props.isAllActivations ? '' : 'margin: auto;'}
  display: grid;
  grid-template-columns: 1fr 620px;
`

export const SelectWrapper = styled.div`
  ${props => props.isAllActivations ? 'margin-left: 50px;' : ''}
`

export const selectStyles = {
  control: styles => (
    {
      ...styles,
      height: '32px',
      width: '214px',
      borderRadius: 0,
      border: '1.5px solid #DBDFE4',
      textTransform: 'uppercase',
      color: theme.black,
      fontSize: rem(11),
      paddingLeft: '10px',
      cursor: 'pointer',
    }
  ),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => (
    {
      // ...styles,
      height: '40px',
      width: '214px',
      border: '1.5px solid #DBDFE4',
      borderTop: 'none',
      paddingLeft: '10px',
      backgroundColor: '#fff',
      color: '#F77294',
      textTransform: 'uppercase',
      fontSize: rem(11),
      display: 'grid',
      alignItems: 'center',
      cursor: 'pointer',
    }
  ),
  menu: styles => ({
    marginTop: '-5px',
    position: 'absolute',
    zIndex: '2',
  })
}

export const InputWrapper = styled.div`
  ${props => props.isAllActivations ? '' : 'margin-left: 200px;'}
  position: relative;

  > input {
    width: 570px;
    height: 48px;
    color: #B3B3B3;
    font-size: ${rem(12)};
    padding-left: 50px;
  }
`

export const Input = styled.input`
  text-transform: uppercase;
`

export const SearchIconWrapper = styled.div`
  position: absolute;
  top: 14px;
  left: 15px;
`

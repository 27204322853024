import React from 'react';

const SliderLeftArrow = ({ fill, height, width, styles }) => (
  <svg style={styles} width={width} height={height} viewBox={"0 0 " + width + " " + height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.6155 3.86364L4.67187 17.7701" stroke={fill} strokeWidth="5" strokeLinecap="square"/>
    <path d="M17.6155 31.3312L3.59642 18.421" stroke={fill} strokeWidth="5" strokeLinecap="square"/>
  </svg>
);

SliderLeftArrow.defaultProps = {
  fill: '#fff',
  height: '35',
  width: '35',
  styles: {
    marginLeft: '15px',
    cursor: 'pointer'
  }
};

export default SliderLeftArrow;

import React from "react";
import AddBrandIcon from "components/atoms/icons/add-brand";
import AddedBrandIcon from "components/atoms/icons/added-brand";
import placeholder from "components/atoms/unavailable-placeholder/index.jpeg";
import {
  Wrapper,
  Column,
  BrandWrapper,
  Image,
  Name,
  AddButtonWrapper,
  AddButtonText,
} from "./styles";

// function for render every brand in a table for the add brands page
const AddBrand = ({ imageUrl, name, isAdded, selectBrand }) => {
  return (
    <Wrapper>
      <Column />
      <Column>
        <BrandWrapper>
          <Image
            src={imageUrl}
            onError={({ target }) => (target.src = placeholder)}
          />
          <Name>{name}</Name>
        </BrandWrapper>
      </Column>
      <Column>
        {isAdded ? (
          <AddButtonWrapper disabled>
            <AddedBrandIcon />
            <AddButtonText>Added</AddButtonText>
          </AddButtonWrapper>
        ) : (
          <AddButtonWrapper onClick={() => selectBrand()}>
            <AddBrandIcon />
            <AddButtonText>Add Brand</AddButtonText>
          </AddButtonWrapper>
        )}
      </Column>
      <Column />
    </Wrapper>
  );
};

export default AddBrand;

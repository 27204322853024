import styled from 'styled-components';
import { rem } from 'polished';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 15% 55% 20% 10%;
  align-items: center;
  height: 100%;
`

export const Column = styled.div`
  display: grid;
  align-items: center;
  height: 100%;
`

export const BrandWrapper = styled.div`
  display: grid;
  grid-template-columns: 55px 1fr;
  align-items: center;
  height: 100%;
`

export const Image = styled.img`
  height: 51px;
  width: 51px;
  object-fit: contain;
`

export const Name = styled.div`
  margin-left: 30px;
  color: #E55879;
  font-size: ${rem(14)};
  font-family: "Helvetica-Bold";
`

export const AddButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  ${props => props.disabled ? '' : 'cursor: pointer;'}
`

export const AddButtonText = styled.div`
  padding-top: 5px;
  font-size: ${rem(14)};
  color: #E55879;
  font-family: "Helvetica-Bold";
`

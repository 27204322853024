import { types, flow, getEnv } from 'mobx-state-tree';

export const MyBrand = types.model('MyBrandModel', {
  id: types.string,
  imageUrl: types.string,
  name: types.string,
  visibleActivations: types.integer,
  accessLevel: types.string
})
.actions(self => ({
  remove: flow(function* remove() {
    try {
      yield getEnv(self).apiClient.delete('syndication/v1/brands/' + self.id)
    } catch (e) {
      console.log(e)
    }
  }),
}))

export const AvailableBrand = types.model('AvailableBrandModel', {
  id: types.string,
  imageUrl: types.string,
  name: types.string
})

import { types, flow, getEnv } from 'mobx-state-tree';
import { MyBrand, AvailableBrand } from 'data-access/stores/models/brands';


export const BrandsStore = types.model('BrandsStore', {
  myBrands: types.array(MyBrand),
  availableBrands: types.array(AvailableBrand),
  isNewBrands: false,
  isLoading: false,
  availableBrandsCount: 0
})
.actions(self => ({
  fetchMyBrands: flow(function* fetchMyBrands(requestParams, requestContinuationToken) {
    self.isLoading = true
    self.isNewBrands = false
    self.myBrands = []

    const url = 'syndication/v1/brands'
    let params = requestParams
    if (requestContinuationToken !== undefined) {
      params = `${requestParams}&continuationToken=${requestContinuationToken}`
    }

    let data
    try {
      data = yield getEnv(self).apiClient.get(url + params)
    } catch(e) {
      console.log(e)
      self.isLoading = false
      return undefined
    }
    const { continuationToken, brands, newBrand } = data.data

    self.myBrands = brands.map(brand => {
      return {
        id: brand.brandId,
        imageUrl: brand.profileImageUrl,
        name: brand.brandName,
        visibleActivations: brand.visibleScheduleCount,
        accessLevel: brand.hasHiddenActivations ? 'selected' : 'all'
      }
    })
    self.isNewBrands = newBrand

    let newContinuationToken = undefined
    if (continuationToken !== undefined) {
      params = `${requestParams}&continuationToken=${continuationToken}`
      let { data } = yield getEnv(self).apiClient.get(url + params)
      const nextContinuationToken = data.continuationToken
      const nextBrands = data.continuationToken
      if (nextContinuationToken !== undefined && nextBrands.length > 0) {
        newContinuationToken = continuationToken
      }
    }

    self.isLoading = false
    return newContinuationToken
  }),
  fetchAvailableBrands: flow(function* fetchAvailableBrands(requestParams, requestContinuationToken, isFirst) {
    self.isLoading = true
    self.availableBrands = []

    const url = 'syndication/v1/brands/all'
    let params = requestParams
    if (requestContinuationToken !== undefined) {
      params = `${requestParams}&continuationToken=${requestContinuationToken}`
    }

    let data
    try {
      data = yield getEnv(self).apiClient.get(url + params)
    } catch(e) {
      console.log(e)
      if (isFirst) {
        self.availableBrandsCount = 0
      }
      self.isLoading = false
      return {
        continuationToken: undefined,
        total: 0
      }
    }
    const { continuationToken, brands, total } = data.data

    self.availableBrands = brands.map(availableBrand => {
      return {
        id: availableBrand.brandId,
        imageUrl: availableBrand.profileImageUrl,
        name: availableBrand.brandName
      }
    })
    if (isFirst) {
      self.availableBrandsCount = total
    }

    let newContinuationToken = undefined
    if (continuationToken !== undefined) {
      params = `${requestParams}&continuationToken=${continuationToken}`
      const { data } = yield getEnv(self).apiClient.get(url + params)
      const nextContinuationToken = data.continuationToken
      const nextBrands = data.brands
      if (nextContinuationToken !== undefined && nextBrands.length > 0) {
        newContinuationToken = continuationToken
      }
    }

    self.isLoading = false
    return {
      continuationToken: newContinuationToken,
      total: total
    }
  }),
  addToMyBrands: flow(function* addToMyBrands(brandsIds) {
    self.isLoading = true
    try {
      for (let i = 0; i < brandsIds.length; i++) {
        yield getEnv(self).apiClient.put('syndication/v1/brands/' + brandsIds[i])
      }
    } catch (e) {
      console.log(e)
    }
    self.isLoading = false
  }),
  changeAccessLevel: flow(function* changeAccessLevel(brandId) {
    self.isLoading = true
    try {
      yield getEnv(self).apiClient.put('syndication/v1/brands/' + brandId + '/visibility')
    } catch (e) {
      console.log(e)
    }
    self.isLoading = false
  }),
}))

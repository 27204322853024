import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Eye from "components/atoms/icons/eye";
import placeholder from "components/atoms/unavailable-placeholder/index.jpeg";
import {
  Wrapper,
  ImageWrapper,
  Hovered,
  Button,
  Image,
  TextsWrapper,
  Price,
  Title,
  Brand,
  EndDate,
} from "./styles";

// class for render every product in a table for the visible products page
class Product extends Component {
  state = {
    isHovered: false,
  };

  changeHovered = (e, status) => {
    this.setState({
      isHovered: status,
    });
    e.preventDefault();
  };

  render() {
    const { isHovered } = this.state;
    const { imageUrl, price, title, brandName, endDateTime, scheduleId } =
      this.props;

    return (
      <Wrapper>
        <ImageWrapper
          onMouseEnter={(e) => this.changeHovered(e, true)}
          onMouseLeave={(e) => this.changeHovered(e, false)}
        >
          {isHovered ? (
            <Hovered>
              <NavLink
                to={`/activation/${scheduleId}`}
                style={{ textDecoration: "none" }}
              >
                <Button>
                  <Eye />
                  view activation
                </Button>
              </NavLink>
            </Hovered>
          ) : (
            ""
          )}
          <Image
            src={imageUrl}
            onError={({ target }) => {
              if (target.src.endsWith('empty.png')) {
                return
              }
              target.src = placeholder
            }}
          />
        </ImageWrapper>
        <TextsWrapper>
          <Price>${price}</Price>
          <Title>{title}</Title>
          <Brand>{brandName}</Brand>
          <EndDate>end: {endDateTime}</EndDate>
        </TextsWrapper>
      </Wrapper>
    );
  }
}

export default Product;

import React from 'react';
import styled from 'styled-components';

const AvatarImage = styled.img`
  height: 32px;
  width: 32px;
  user-select: none;
  border-radius: 50%;
  object-fit: contain;
`

// func for topnav user dropdown avatar image
const Avatar = ({url}) => <AvatarImage src={url} />

export default Avatar

import React, { Component, Fragment } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Select from 'react-select';
import SearchIcon from 'components/atoms/icons/search';
import { NewPaginator } from 'components/molecules/paginator';
import AddBrand from 'components/molecules/add-brand';
import Loader from 'components/atoms/loader';
import SelectedBrands from 'components/molecules/selected-brands';
import {
  Wrapper,
  PageSection,
  PageSectionTitle,
  PageSectionButton,
  SortingSection,
  SelectWrapper,
  selectStyles,
  InputWrapper,
  Input,
  Table,
  Row,
  EmptyTable,
  EmptyRow
} from './styles';

const options = [
  {value: 'createdOn:desc', label : 'newest'},
  {value: 'brandName:asc', label : 'name a-z'},
  {value: 'brandName:desc', label : 'name z-a'},
]

// class for render main component (wo top and bottom navs) in the add brands page
class AddBrands extends Component {
  state = {
    selectedSorting: Object.create(options[0]),
    query: '',
    page: 0,
    pageSize: 10,
    selectedBrands: [],
    isRedirect: false,
    continuationTokens: [],
    currentTotal: 0
  }
  
  async updateInfo(state, isFirst=false) {
    const { page, pageSize, selectedSorting, query, continuationTokens } = state
    let params = `?pageLimit=${pageSize}`
    const sorting = selectedSorting.value.split(':')
    params += `&sortedBy=${sorting[0]}&sortOrder=${sorting[1]}`
    if (query) {
      params += `&q=${query}`
    }
    const continuationTokenIndex = page - 1
    const continuationToken = continuationTokens[continuationTokenIndex]
    const { fetchAvailableBrands } = this.props.store.brandsStore
    const response = await fetchAvailableBrands(params, continuationToken, isFirst)
    this.setState({
      currentTotal: response.total
    })
    if (response.continuationToken !== undefined) {
      this.setState({
        continuationTokens: [...continuationTokens.slice(0, page), response.continuationToken]
      })
    }
  }

  async componentDidMount() {
    await this.updateInfo(this.state, true)
  }

  async sortBrands(selected) {
    const { selectedSorting } = this.state
    if (selectedSorting.value !== selected.value) {
      this.setState({
        selectedSorting: Object.create(selected),
        page: 0,
        continuationTokens: []
      }, function() {
        this.updateInfo(this.state)
      })
    }
  }

  handleChangeSearchText(e) {
    e.preventDefault()
    this.setState({ query: e.target.value })
  }

  selectBrand = (brand) => {
    let { selectedBrands } = this.state
    selectedBrands.push({
      id: brand.id,
      imageUrl: brand.imageUrl,
      name: brand.name
    })
    this.setState({
      selectedBrands: selectedBrands
    })
  }

  unselectAllBrands = () => {
    this.setState({
      selectedBrands: []
    })
  }

  unselectBrand = brand => {
    const { selectedBrands } = this.state
    const newBrands = selectedBrands.filter(selectedBrand => !(selectedBrand.id === brand.id))
    this.setState({
      selectedBrands: newBrands
    })
  }

  async confirmSelectedBrands() {
    const { addToMyBrands } = this.props.store.brandsStore
    const { selectedBrands } = this.state
    const selectedBrandsIds = selectedBrands.map(item => item.id)
    await addToMyBrands(selectedBrandsIds)
    this.setState({
      page: 0,
      continuationTokens: [],
      selectedBrands: [],
      isRedirect: true
    })
  }

  changePage = (delta) => {
    this.setState((prevState) => {
      return {
        page: prevState.page + delta
      }
    }, function() {
      this.updateInfo(this.state)
    })
  }

  handleSearchInputKeyDown = ({ key }) => {
    if (key !== 'Enter') return
    this.updateInfo(this.state)
  }

  render() {
    const { isLoading, availableBrands, availableBrandsCount } = this.props.store.brandsStore
    const { page, selectedBrands, selectedSorting, continuationTokens, currentTotal, isRedirect } = this.state
    if (!selectedSorting.label.startsWith('sort by: ')) {
      selectedSorting.label = 'sort by: ' + selectedSorting.label
    }

    const hasNext = continuationTokens[page] !== undefined ? true : false
    const hasPrev = page > 0 ? true : false

    if (isRedirect) {
      return <Redirect to='/brands' />
    }

    return (
      <Wrapper>
        {
          isLoading
            ? <Loader />
            : (
              <>
                {selectedBrands.length === 0 ?
                <PageSection>
                  <PageSectionTitle>Select from the list below to add brands.</PageSectionTitle>
                  <PageSectionButton>
                    <NavLink to='/brands'>
                      cancel
                    </NavLink>
                  </PageSectionButton>
                </PageSection> :
                <SelectedBrands
                  brands={selectedBrands}
                  cancel={() => this.unselectAllBrands()}
                  done={() => this.confirmSelectedBrands()}
                  unselect={(brand) => this.unselectBrand(brand)}
                />
              }

              <SortingSection>
                <SelectWrapper>
                  
                  brands available ({currentTotal !== 0 ?
                    currentTotal :
                    availableBrandsCount
                  })
                  <Select
                    options={options}
                    value={selectedSorting}
                    styles={selectStyles}
                    isSearchable={false}
                    onChange={(selected) => this.sortBrands(selected)}
                  />
                </SelectWrapper>
                <InputWrapper>
                  <SearchIcon />
                  <Input
                    placeholder={'search brands'}
                    onChange={e => this.handleChangeSearchText(e)}
                    onKeyDown={e => this.handleSearchInputKeyDown(e)}
                    value={this.state.query}
                  />
                </InputWrapper>
              </SortingSection>

        {availableBrandsCount === 0
          ? <EmptyTable>You currently have no brands available.</EmptyTable>
          : availableBrands.length === 0
            ? (
                <Table>
                  <EmptyRow>None found.</EmptyRow>
                </Table>
            )
            : (
              <Fragment>
                <Table>
                  {availableBrands.map(brand => (
                    <Row key={brand.id}>
                      <AddBrand
                        key={brand.id}
                        imageUrl={brand.imageUrl}
                        name={brand.name}
                        isAdded={selectedBrands.find(selectedBrand => selectedBrand.id === brand.id)}
                        selectBrand={() => this.selectBrand(brand)}
                      />
                    </Row>
                  ))}
                </Table>

                <NewPaginator
                  page={page}
                  hasNext={hasNext}
                  hasPrev={hasPrev}
                  changePage={(delta) => this.changePage(delta)}
                />

              </Fragment>
            )
        }
      </>
      )
        }
      </Wrapper>
    )
  }
}

export default inject('store')(observer(AddBrands))

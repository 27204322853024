import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';


export class Logout extends Component {
  componentDidMount() {
    window.open(process.env.REACT_APP_BRAND_DASH_URL_BASE + 'logout', '_blank')
    const { logout } = this.props.store.authStore;
    logout();
  }

  render() {
    return <div></div>
  }
}

export default inject('store')(observer(Logout))

import React from "react";
import { NavLink } from "react-router-dom";
import OkSmall from "components/atoms/icons/ok-small";
import Eye from "components/atoms/icons/eye";
import Cross from "components/atoms/icons/cross";
import placeholder from "components/atoms/unavailable-placeholder/index.jpeg";
import {
  Wrapper,
  HoweredColumn,
  ButtonsWrapper,
  Button,
  Column,
  Image,
  TitleWrapper,
  BrandName,
  Title,
  Description,
  ProductsWrapper,
  ProductsCount,
  ProductsImages,
  ProductsImage,
  ProductsArrow,
  StatusWrapper,
  Status,
  Date,
} from "./styles";

const Activation = ({
  id,
  imageUrl,
  brandName,
  title,
  description,
  products,
  status,
  startDateTime,
  endDateTime,
  unavailableProductsCount,
  makeVisible,
  hide,
}) => {
  const allProducts = [
    ...products,
    ...Array(unavailableProductsCount || 0).fill(null)
  ]

  return (
    <Wrapper>
      <Column>
        <Image src={imageUrl ? imageUrl : placeholder} />
      </Column>
      <Column>
        <TitleWrapper>
          <BrandName>{brandName}</BrandName>
          <Title>{title}</Title>
        </TitleWrapper>
      </Column>
      <Column>
        <Description>{description}</Description>
      </Column>
      <Column>
        <ProductsWrapper>
          <ProductsCount>
            {allProducts.length === 0 ? "None" : allProducts.length}
          </ProductsCount>
          {allProducts.length === 0 ? (
            ""
          ) : (
            <ProductsImages>
              {allProducts.slice(0, 6).map((product) => (
                <ProductsImage
                  key={product}
                  src={product ? product.imageUrl : placeholder}
                />
              ))}
            </ProductsImages>
          )}
          {allProducts.length > 6 && <ProductsArrow>{">"}</ProductsArrow>}
        </ProductsWrapper>
      </Column>
      <Column>
        <StatusWrapper>
          <Status status={status}>{status}</Status>
          <Date>START: {startDateTime}</Date>
          <Date>END: {endDateTime}</Date>
        </StatusWrapper>
      </Column>
  
      {/* Hover behavior */}
      <HoweredColumn className="hovered-activation">
        <ButtonsWrapper>
          <NavLink to={`/activation/${id}`} style={{ textDecoration: "none" }}>
            <Button>
              view
              <Eye styles={{ top: "9px", left: "20px" }} />
            </Button>
          </NavLink>
          {status === "visible" ? (
            <Button onClick={() => hide()}>
              hide
              <Cross styles={{ top: "8px", left: "25px" }} />
            </Button>
          ) : (
            <Button onClick={() => makeVisible()}>
              make visible
              <OkSmall styles={{ top: "6px", left: "17px" }} />
            </Button>
          )}
        </ButtonsWrapper>
      </HoweredColumn>
    </Wrapper>
  )
}

export default Activation;

import { types, flow, getEnv } from 'mobx-state-tree';
import { Content } from 'data-access/stores/models/content';

export const ContentsStore = types.model('ContentsStore', {
  contents: types.array(Content),
  isLoading: false
})
.actions(self => ({
  fetchContents: flow(function* fetchContents(requestParams, requestContinuationToken) {
    self.isLoading = true
    self.contents = []

    const url = 'syndication/v1/schedules'
    let params = requestParams
    if (requestContinuationToken !== undefined) {
      params = `${requestParams}&continuationToken=${requestContinuationToken}`
    }

    let data
    try {
      data = yield getEnv(self).apiClient.get(url + params)
    } catch(e) {
      console.log(e)
      self.isLoading = false
      return undefined
    }
    const { continuationToken, schedules } = data.data

    self.contents = schedules.map(content => {
      return {
        id: content.scheduleId,
        imageUrl: content.mediaUrls[0],
        brandName: content.brandName,
        title: content.title,
        description: content.description || '',
        scheduleId: content.scheduleId,
        startDateTime: new Date(content.startDate),
        endDateTime: new Date(content.endDate)
      }
    })

    let newContinuationToken = undefined
    if (continuationToken !== undefined) {
      params = `${requestParams}&continuationToken=${continuationToken}`
      let { data } = yield getEnv(self).apiClient.get(url + params)
      const nextContinuationToken = data.continuationToken
      const nextActivations = data.schedules
      if (nextContinuationToken !== undefined && nextActivations.length > 0) {
        newContinuationToken = continuationToken
      }
    }

    self.isLoading = false
    return newContinuationToken
  }),
}))

import styled from 'styled-components';
import { rem } from 'polished';

export const Wrapper = styled.div`
  height: 110px;
  width: 100%;
  border-top: 1.5px solid #E5EAF1;
  padding: 0px 20px;
  display: grid;
  grid-template-columns: 75px 1fr;
  grid-gap: 20px;

  &:last-of-type {
    border-bottom: 1.5px solid #ededed;
  }
  &:nth-child(even) {
    background-color: #fff;
  }
`

export const ImageWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
`

export const Image = styled.img`
  max-height: 75px;
  max-width: 75px;
`

export const TextWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  font-weight: bold;
`

export const Text = styled.p`
  height: 54px;
  color: ${props => props.isPrice ? '#D14E6F' : '#000'};
  font-size: ${rem(12)};
  line-height: 16px;
  display: grid;

  &:nth-child(1) {
    align-items: end;
  }
`

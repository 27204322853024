import React from 'react';

const Ok = ({ fill, height, width }) => (
  <svg width={width} height={height} viewBox={"0 0 " + width + " " + height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.4153 7.2731L9.36103 20.3274" stroke={fill} strokeWidth="3.1104" strokeLinecap="square"/>
    <path d="M2.50753 13.4739L8.14884 19.1152" stroke={fill} strokeWidth="3.1104" strokeLinecap="square"/>
  </svg>
);

Ok.defaultProps = {
  fill: '#884959',
  height: '23',
  width: '25'
};

export default Ok;

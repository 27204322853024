import React from 'react';

const Cross = ({ fill, height, width, styles }) => (
  <svg style={styles} width={width} height={height} viewBox={"0 0 " + width + " " + height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17 2.36634L15.6337 1L9 7.65347L2.34653 1L1 2.36634L7.63366 9L1 15.6535L2.34653 17L9 10.3663L15.6337 17L17 15.6535L10.3465 9L17 2.36634Z" fill={fill} stroke="black" strokeWidth="0.5"/>
  </svg>
);

Cross.defaultProps = {
  fill: '#000',
  height: '18',
  width: '18',
  styles: {}
};

export default Cross;

import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Helvetica-Bold';
    src: url('${process.env.PUBLIC_URL}/fonts/2B33CD_1_0.eot');
    src: url('${process.env.PUBLIC_URL}/fonts/2B33CD_1_0.eot?#iefix') format('embedded-opentype'),
         url('${process.env.PUBLIC_URL}/fonts/2B33CD_1_0.woff')       format('woff'),
         url('${process.env.PUBLIC_URL}/fonts/2B33CD_1_0.ttf')        format('truetype');
    font-weight: normal;
    font-style: normal;  
  }


  @font-face {
    font-family: 'Helvetica-Normal';
    src: url('${process.env.PUBLIC_URL}/fonts/2B33CD_0_0.eot')  format('eot'),
         url('${process.env.PUBLIC_URL}/fonts/2B33CD_0_0.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }


  @font-face {
    font-family: 'Helvetica-Medium';
    src: url('${process.env.PUBLIC_URL}/fonts/2B33CD_2_0.eot')  format('eot'),
         url('${process.env.PUBLIC_URL}/fonts/2B33CD_2_0.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }


  @font-face {
    font-family: 'Helvetica-Light';
    src: url('${process.env.PUBLIC_URL}/fonts/2D262E_0_0.eot')  format('eot'),
         url('${process.env.PUBLIC_URL}/fonts/2D262E_0_0.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Helvetica-Normal';
  }
`

export default GlobalStyles

import styled from 'styled-components';
import { rem } from 'polished';

export const Wrapper = styled.div`
  min-height: 162px;
  width: 1024px;
  margin: 30px auto;
  background-color: #F7E4E4;
  border: 1px solid #CDCDCD;
  border-radius: 17px;
  padding: 25px 20px;
`

export const ButtonsWrapper = styled.div`
  margin: auto;
  margin-right: 0;
  width: fit-content;
`

export const Button = styled.div`
  height: 37px;
  width: 165px;
  border: 1.5px solid #3c3c3c;
  padding-top: 1.5px;
  border-radius: 19px;
  font-size: ${rem(12)};
  font-family: "Helvetica-Bold";
  color: #3c3c3c;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-grid;
  margin-left: 20px;
  cursor: pointer;
  align-content: center;
  justify-content: center;
  box-sizing: border-box;
`

export const BrandsWrapper = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 58px repeat(3, 288px) 58px;
  margin-top: 20px;
`

export const BrandWrapper = styled.div`
  min-height: 56px;
  width: 268px;
  background-color: #FFFFFF;
  border: 1.5px solid #E5EAF1;
  display: grid;
  align-items: center;
  grid-template-columns: 51px 1fr 50px;
  margin: auto;
`

export const BrandImage = styled.img`
  height: 51px;
  width: 51px;
  object-fit: contain;
`
export const BrandName = styled.div`
  padding-left: 20px;
  font-size: ${rem(14)};
  color: #4A4A4A;
  font-family: "Helvetica-Bold";
`

export const CrossWrapper = styled.div`
  margin-top: 5px;
`

export const PaginationArrowWrapper = styled.div`
  margin-top: 7px;
`

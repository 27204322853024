import styled from "styled-components";


export const ThumbnailWrapper = styled.div`
  > span {
    width: unset;
  }
`

export const DropZoneWrapper = styled.div`
  margin: 1.6rem 0;
`

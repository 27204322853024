import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import theme from 'lib/styles/theme';

const ButtonWrapper = styled.div`
  height: 56px;
  width: 260px;
  border: 2px solid ${theme.pink.dark};
  font-size: ${rem(20)};
  text-transform: uppercase;
  color: ${theme.pink.dark};
  display: grid;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  background-color: transparent;
  &:hover {
    background-color: ${theme.pink.dark};
    color: ${theme.pink.light};
  }
`

// function for button render
const Button = ({text, clickAction, styles}) => <ButtonWrapper style={styles} onClick={clickAction}>{text}</ButtonWrapper>

export default Button

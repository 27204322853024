import { types, onSnapshot } from 'mobx-state-tree';
import axios from 'axios';
import Auth from 'lib/common/auth-service';
import { AuthStore } from 'data-access/stores/auth';
import { BrandsStore } from 'data-access/stores/brands';
import { ActivationsStore } from 'data-access/stores/activation';
import { ProductsStore } from 'data-access/stores/product';
import { ContentsStore } from 'data-access/stores/content';

const authService = new Auth();

let authState = {}
let brandsState = {}
let activationsState = {}
let productsState = {}
let contentsState = {}

const RootStore = types.model({
  authStore: AuthStore,
  brandsStore: BrandsStore,
  activationsStore: ActivationsStore,
  productsStore: ProductsStore,
  contentsStore: ContentsStore
})

const getToken = () => localStorage.getItem('token') || ''

const getHeaderToken = () => {
  const token = getToken()
  if (token === '') return {}
  return {headers: {Authorization: `Bearer ${token}`}}
}

const createApiClient = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
  instance.interceptors.response.use(
    response => {
      return response
    },
    function (error) {
      return Promise.reject(error.response.data)
    }
  )

  const apiClient = {
    get: (endpoint, config) => {
      return instance.get(endpoint, {
        ...config,
        ...getHeaderToken()
      })
    },
    put: (endpoint, data) => instance.put(endpoint, data, { ...getHeaderToken() }),
    delete: (endpoint) => instance.delete(endpoint, { ...getHeaderToken() }),
    post: (endpoint, data) => instance.post(endpoint, data, { ...getHeaderToken() }),
    uploadMedia: (endpoint, data) => axios.put(endpoint, data, {headers: {'Content-Type': data.type}})
  }
  return apiClient
}

if (localStorage.getItem('authStore')) {
  const json = JSON.parse(localStorage.getItem('authStore'))
  if (AuthStore.is(json)) authState = json
}

if (localStorage.getItem('brandsStore')) {
  const json = JSON.parse(localStorage.getItem('brandsStore'))
  if (BrandsStore.is(json)) brandsState = json
}

if (localStorage.getItem('activationsStore')) {
  const json = JSON.parse(localStorage.getItem('activationsStore'))
  if (ActivationsStore.is(json)) activationsState = json
}

if (localStorage.getItem('productsStore')) {
  const json = JSON.parse(localStorage.getItem('productsStore'))
  if (ProductsStore.is(json)) productsState = json
}

if (localStorage.getItem('contentsStore')) {
  const json = JSON.parse(localStorage.getItem('contentsStore'))
  if (ContentsStore.is(json)) contentsState = json
}

const rootStore = RootStore.create(
  {
    authStore: AuthStore.create(authState),
    brandsStore: BrandsStore.create(brandsState),
    activationsStore: ActivationsStore.create(activationsState),
    productsStore: ProductsStore.create(productsState),
    contentsStore: ContentsStore.create(contentsState)
  },
  {
    apiClient: createApiClient(),
    authService: authService
  }
)

onSnapshot(rootStore.authStore, snapshot => {
  localStorage.setItem('authStore', JSON.stringify(snapshot))
})

onSnapshot(rootStore.brandsStore, snapshot => {
  localStorage.setItem('brandsStore', JSON.stringify(snapshot))
})

onSnapshot(rootStore.activationsStore, snapshot => {
  localStorage.setItem('activationsStore', JSON.stringify(snapshot))
})

onSnapshot(rootStore.productsStore, snapshot => {
  localStorage.setItem('productsStore', JSON.stringify(snapshot))
})

onSnapshot(rootStore.contentsStore, snapshot => {
  localStorage.setItem('contentsStore', JSON.stringify(snapshot))
})

export default rootStore

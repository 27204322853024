import Modal from 'react-modal';
import styled from 'styled-components';
import { rem } from 'polished';
import theme from 'lib/styles/theme';

export const ModalContainer = styled(Modal)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 3;
`

export const ModalContent = styled.div`
  width: 615px;
  background-color: ${theme.white};
  border: 1px solid #979797
  border-radius: 17px;
  padding: 30px 0;
`

export const ModalTitle = styled.div`
  color: #000;
  text-align: center;
  text-transform: uppercase;
  font-size: ${rem(20)};
  margin-bottom: 30px;
`

export const ModalText = styled.div`
  color: #000;
  text-align: center;
  font-size: ${rem(15)};
  margin-bottom: 15px;
  padding: 0 80px;
`

export const ModalLink = styled.div`
  color: #E55879;
  text-align: center;
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
  font-size: ${rem(12)};
  margin-top: 30px;
`

export const ModalButtonWrapper = styled.div`
  margin-top: 30px;
  height: 38px;
  display: grid;
  justify-content: center;
`

export const ModalButton = styled.div`
  width: 180px;
  border: 1.5px solid #3c3c3c;
  border-radius: 19px;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: ${rem(12)};
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
`

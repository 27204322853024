import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import TopNav from 'components/molecules/top-nav';
import BottomNav from 'components/molecules/bottom-nav';
import { Wrapper } from './styles';

// function for render current main components and navbars
const PrivateRoute = ({ component: Component, authStore, ...rest }) => {
  return (
    <Route
      {...rest}
      component={() =>
        authStore.isAuthed() ?
          <Fragment>
            <TopNav />
            <Wrapper>
              <Component />
              <BottomNav />
            </Wrapper>
          </Fragment> :
          <Redirect to='/login' />
      }
    />
  )
}

export default PrivateRoute

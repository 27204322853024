import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Eye from "components/atoms/icons/eye";
import placeholder from "components/atoms/unavailable-placeholder/index.jpeg";
import {
  Wrapper,
  ImageWrapper,
  Hovered,
  Button,
  Image,
  TextsWrapper,
  Brand,
  Title,
  Description,
  Date,
} from "./styles";

// class for render every content in a table for the visible content page
class Content extends Component {
  state = {
    isHovered: false,
  };

  changeHovered = (e, status) => {
    this.setState({
      isHovered: status,
    });
    e.preventDefault();
  };

  render() {
    const { isHovered } = this.state;
    const {
      imageUrl,
      brandName,
      title,
      description,
      startDateTime,
      endDateTime,
      scheduleId,
    } = this.props;

    return (
      <Wrapper
        onMouseEnter={(e) => this.changeHovered(e, true)}
        onMouseLeave={(e) => this.changeHovered(e, false)}
      >
        {isHovered ? (
          <Hovered>
            <NavLink
              to={`/activation/${scheduleId}`}
              style={{ textDecoration: "none" }}
            >
              <Button>
                <Eye />
                view activation
              </Button>
            </NavLink>
          </Hovered>
        ) : (
          ""
        )}
        <ImageWrapper>
          <Image
            src={imageUrl}
            onError={({ target }) => (target.src = placeholder)}
          />
        </ImageWrapper>
        <TextsWrapper>
          <Brand>{brandName}</Brand>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <Date>start: {startDateTime}</Date>
          <Date>end: {endDateTime}</Date>
        </TextsWrapper>
      </Wrapper>
    );
  }
}

export default Content;

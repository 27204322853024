import React from "react";
import FooterLogoBig from "components/atoms/icons/FooterLogoBig";
import FooterLogoSmall from "components/atoms/icons/FooterLogoSmall";
import helperWindow from "helpers/window";
import "./styles.css";

// function for render bottom navigation on every page
const BottomNav = () => {
  const restLinksProps = helperWindow.isInIframe()
    ? { target: "_blank", rel: "noopener noreferrer" }
    : {};

  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="footer__logo-column">
          <FooterLogoBig className="footer__logo-big" />
          <FooterLogoSmall className="footer__logo-small" />
        </div>
        <div className="footer__services-column">
          <p className="footer__column-title">SERVICES</p>
          <a
            className="footer__column-item"
            href="https://distll.com/brands-and-creators"
            {...restLinksProps}
          >
            DISTLL for Brands
          </a>
          <a
            className="footer__column-item"
            href="https://distll.com/retailers"
            {...restLinksProps}
          >
            DISTLL for Retailers
          </a>
          <a
            className="footer__column-item"
            href="https://distll.com/support"
            target="_blank"
            rel="noopener noreferrer"
          >
            Support
          </a>
        </div>
        <div className="footer__company-column">
          <p className="footer__column-title">COMPANY</p>
          <a
            className="footer__column-item"
            href="https://distll.com/about"
            {...restLinksProps}
          >
            About
          </a>
          <a
            className="footer__column-item"
            href="https://distll.com/contact"
            {...restLinksProps}
          >
            Contact
          </a>
          <a
            className="footer__column-item"
            href="https://distll.com/app-documentation"
            {...restLinksProps}
          >
            App documentation
          </a>
        </div>
        <div className="footer__legal-column">
          <p className="footer__column-title">LEGAL</p>
          <a
            className="footer__column-item"
            href="https://distll.com/terms/terms-of-service"
            {...restLinksProps}
          >
            Terms of Service
          </a>
          <a
            className="footer__column-item"
            href="https://distll.com/terms/privacy-policy"
            {...restLinksProps}
          >
            Privacy Policy
          </a>
        </div>
        <div className="footer__copyright-column">
          © {new Date().getFullYear()} DISTLL. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};

export default BottomNav;

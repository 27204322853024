import React from 'react';

const AddBrand = ({ fill, height, width, styles }) => (
  <svg style={styles} width={width} height={height} viewBox={"0 0 " + width + " " + height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.498 7.04245V18.1747" stroke={fill} strokeWidth="1.7496" strokeLinecap="square"/>
    <path d="M6.61768 12.4192H18.3782" stroke={fill} strokeWidth="1.7496" strokeLinecap="square"/>
    <rect x="0.675" y="0.675" width="23.65" height="23.65" rx="11.825" stroke={fill} strokeWidth="1.35"/>
  </svg>
);

AddBrand.defaultProps = {
  fill: '#FF7293',
  height: '25',
  width: '25',
  styles: {}
};

export default AddBrand;

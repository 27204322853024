import auth0 from 'auth0-js';
import { CONFIG } from 'constants/auth0-variables';

export default class Auth {
  accessToken;
  idToken;
  expiresAt;
  userProfile;
  scopes;
  auth0Manage;

  auth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_CLIENT_ID,
    redirectUri: `${CONFIG.url}/callback`,
    responseType: 'token id_token',
    scope:
      'openid profile email read:current_user update:current_user_metadata',
    audience: process.env.REACT_APP_AUTH0_AUDIENCE
  });


  constructor() {
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.checkSession = this.checkSession.bind(this);
    this.setSession = this.setSession.bind(this);
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        localStorage.setItem('token', authResult.idToken);
        this.idToken = authResult.idToken;
        if (err) return reject(err);

        if (!authResult || !authResult.idToken) {
          return reject(err);
        }

        this.setSession(authResult);
        resolve(authResult);
      });
    });
  }

  setSession(authResult) {
    // Set isLoggedIn flag in localStorage
    localStorage.setItem('isLoggedIn', 'true');
    // Set the time that the access token will expire at
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    localStorage.setItem('expires_at', expiresAt);
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;

    // Set the users scopes
    this.scopes = authResult.scope || '';
  }

  logOut = () => {
    localStorage.clear();
    this.auth0.logout({
      returnTo: `${CONFIG.url}/login`
    });
  };

  checkSession() {
    this.auth0.checkSession(
      {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'read:current_user'
      },
      (err, result) => {
        console.log('check session', result);
      }
    );
  }
}

import styled from 'styled-components';
import { rem } from 'polished';
import theme from 'lib/styles/theme';

export const Wrapper = styled.div`
`

export const PageSection = styled.div`
  height: 90px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: ${theme.pink.light};
  padding: 0 30px;
`

export const PageSectionTitle = styled.div`
  display: grid;
  align-items: center;
  justify-content: right;
  color: #000;
  font-size: ${rem(14)};
  position: relative;
  right: -120px;
`

export const PageSectionButton = styled.div`
  display: grid;
  align-items: center;
  justify-content: right;

  > a {
    height: 37px;
    width: 165px;
    border: 1.5px solid #3c3c3c;
    padding-top: 1.5px;
    border-radius: 19px;
    font-size: ${rem(12)};
    font-family: "Helvetica-Bold";
    color: #3c3c3c;
    text-transform: uppercase;
    text-decoration: none;
    display: grid;
    align-items: center;
    justify-content: center;
  }
`

export const SortingSection = styled.div`
  height: 150px;
  width: 70%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 435px;
  justify-content: end;
  align-items: end;
  padding-bottom: 30px;
`

export const SelectWrapper = styled.div`
  width: 100%;
  color: #E55879;
  font-size: ${rem(16)};
  text-transform: uppercase;
`

export const selectStyles = {
  control: styles => (
    {
      ...styles,
      height: '32px',
      width: '214px',
      borderRadius: 0,
      border: '1.5px solid #DBDFE4',
      textTransform: 'uppercase',
      color: theme.black,
      fontSize: rem(11),
      paddingLeft: '10px',
      cursor: 'pointer',
      marginTop: '15px'
    }
  ),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => (
    {
      // ...styles,
      height: '40px',
      width: '214px',
      border: '1.5px solid #DBDFE4',
      borderTop: 'none',
      paddingLeft: '10px',
      backgroundColor: '#fff',
      color: '#F77294',
      textTransform: 'uppercase',
      fontSize: rem(11),
      display: 'grid',
      alignItems: 'center',
      cursor: 'pointer',
    }
  ),
  menu: styles => ({
    marginTop: '-5px',
    position: 'absolute',
    zIndex: '2',
  })
}

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  color: #B3B3B3;

  > svg {
    position: absolute;
    top: 15px;
    left: 15px;
  }
`

export const Input = styled.input`
  width: 100%;
  height: 50px;
  border: 1.5px solid #DBDFE4;
  background-color: #FFFFFF;

  color: #B3B3B3;
  text-transform: uppercase;
  font-size: ${rem(12)};
  padding-left: 50px;
`

export const Table = styled.div``

export const Row = styled.div`
  height: 60px;
  border-top: 1.5px solid #ededed;

  &:last-of-type {
    border-bottom: 1.5px solid #ededed;
  }
  &:nth-child(odd) {
    background-color: #faf9f9;
  }
`

export const EmptyTable = styled.div`
  min-height: calc(100vh - 640px);
  width: 100%;
  background-color: #EDEDED;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: ${rem(18)};
  color: #838383;
`

export const EmptyRow = styled.div`
  height: 60px;
  border: 1.5px solid #ededed;
  background-color: #faf9f9;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: ${rem(17)};
  color: #838383;
  `
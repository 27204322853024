import React from 'react';

const OkSmall = ({ fill, height, width, styles }) => (
  <svg style={styles} width={width} height={height} viewBox={"0 0 " + width + " " + height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6.29295L7.58423 16.9777" stroke={fill} strokeWidth="1.92" strokeLinecap="square"/>
    <path d="M2.3072 11.7008L7.16401 16.5576" stroke={fill} strokeWidth="1.92" strokeLinecap="square"/>
  </svg>
);

OkSmall.defaultProps = {
  fill: '#000',
  height: '19',
  width: '20',
  styles: {}
};

export default OkSmall;

import React, { Component, Fragment } from "react";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Modal, { buildModal } from "components/atoms/modal";
import Chain from "components/atoms/icons/chain";
import Loader from "components/atoms/loader";
import ActivationProduct from "components/molecules/activation-product";
import placeholder from "components/atoms/unavailable-placeholder/index.jpeg";
import {
  Wrapper,
  TopNavBar,
  PageSectionWrapper,
  PageSection,
  PageSectionActivationTitle,
  ButtonsWrapper,
  Button,
  ContentWrapper,
  ContentTitleWrapper,
  CardsWrapper,
  Card,
  CardTitle,
  CardContent,
  CardText,
  ImageWrapper,
  Image,
  Description,
  ChainWrapper,
  ProductsWrapper,
} from "./styles";

// class for render main component (wo top and bottom navs) in the activation page
class Activation extends Component {
  state = {
    isActivationToHide: false,
    isRedirect: false,
  };

  async updateInfo() {
    const id = this.props.match.params.id;
    const { fetchCurrentActivation } = this.props.store.activationsStore;
    await fetchCurrentActivation(id);
  }

  async componentDidMount() {
    await this.updateInfo();
  }

  makeVisible() {
    const { currentActivation, changeStatus } =
      this.props.store.activationsStore;
    changeStatus(currentActivation.id);
    this.setState({
      isRedirect: true,
    });
  }

  hideActivation = () => {
    this.setState({
      isActivationToHide: true,
    });
  };

  confirmHide() {
    const { currentActivation, changeStatus } =
      this.props.store.activationsStore;
    changeStatus(currentActivation.id);
    this.setState({
      isRedirect: true,
    });
  }

  redirect = () => {
    const { isRedirect } = this.state;
    if (isRedirect) {
      return <Redirect to="/my-api" />;
    }
  };

  cancelHide = () => {
    this.setState({
      isActivationToHide: false,
    });
  };

  formatDate = (date) => {
    let result =
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      " @ ";
    let hours = date.getHours();
    result += hours > 12 ? hours - 12 + " PM" : hours + " AM";
    return result;
  };

  render() {
    const { isLoading, currentActivation } = this.props.store.activationsStore;
    if (isLoading || currentActivation === null) {
      return <Loader styles={{ margin: "100px auto" }} />;
    }
    const { isActivationToHide } = this.state;

    const modal = buildModal(
      "hide activation",
      [
        "Are you sure you’d like to hide this activation from your API feed?",
        "Doing so will switch its status to “hidden” and will remove all related products and content from being visible to end consumers.",
      ],
      "yes, hide this activation.",
      () => this.confirmHide(),
      "cancel",
      () => this.cancelHide()
    );

    return (
      <Wrapper>
        <Modal isOpen={isActivationToHide} component={modal} />
        {this.redirect()}

        <TopNavBar>
          <PageSectionWrapper>
            <PageSection>home / activations /</PageSection>
            <PageSectionActivationTitle>
              {currentActivation.title}
            </PageSectionActivationTitle>
          </PageSectionWrapper>
          <ButtonsWrapper>
            {currentActivation.status === "hidden" ? (
              <Fragment>
                <NavLink to="/my-api" style={{ textDecoration: "none" }}>
                  <Button>close</Button>
                </NavLink>
                <Button onClick={() => this.makeVisible()}>make visible</Button>
              </Fragment>
            ) : (
              <Fragment>
                <NavLink to="/my-api" style={{ textDecoration: "none" }}>
                  <Button>close</Button>
                </NavLink>
                <Button onClick={() => this.hideActivation()}>hide</Button>
              </Fragment>
            )}
          </ButtonsWrapper>
        </TopNavBar>

        <ContentWrapper>
          <ContentTitleWrapper>
            <p>title</p>
            <p>start: {this.formatDate(currentActivation.startDateTime)}</p>
            <p style={{ textTransform: "none" }}>{currentActivation.title}</p>
            <p>end: {this.formatDate(currentActivation.endDateTime)}</p>
          </ContentTitleWrapper>

          <CardsWrapper>
            <Card>
              <CardTitle>story</CardTitle>
              <CardContent>
                <CardText isFirst>type</CardText>
                <CardText>{currentActivation.mediaType}</CardText>
                <ImageWrapper>
                  <Image
                    src={currentActivation.imageUrl}
                    onError={({ target }) => {
                      if (target.src.endsWith('empty.png')) {
                        return
                      }
                      target.src = placeholder
                    }}
                  />
                </ImageWrapper>
                <CardText isFirst isBold>
                  description
                </CardText>
                <Description>{currentActivation.description}</Description>
              </CardContent>
            </Card>
            <ChainWrapper>
              <Chain />
            </ChainWrapper>
            <Card>
              <CardTitle>products</CardTitle>
              <ProductsWrapper>
                {currentActivation.products.map((product) => (
                  <ActivationProduct
                    key={product.id}
                    imageUrl={product.imageUrl}
                    price={product.price}
                    title={product.title}
                  />
                ))}
              </ProductsWrapper>
            </Card>
          </CardsWrapper>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

export default withRouter(inject("store")(observer(Activation)));

import React from 'react';
import { NavLink } from 'react-router-dom';
import Select from 'react-select';
import Search from 'components/atoms/icons/search';
import {
  Wrapper,
  PageSection,
  InputsWrapper,
  SelectWrapper,
  selectStyles,
  InputWrapper,
  Input,
  SearchIconWrapper
} from './styles';

const MyApiTopNav = ({isAllActivations, selectOptions, selectedOption, selectOnChange, inputPlaceholder, inputOnChange}) => {
  return (
    <Wrapper>
      <PageSection>
        <NavLink to='/my-api/all'>all activations</NavLink>
        <NavLink to='/my-api/visible-products'>visible products</NavLink>
        <NavLink to='/my-api/visible-content'>visible content</NavLink>
      </PageSection>

      <InputsWrapper isAllActivations={isAllActivations}>
        <SelectWrapper isAllActivations={isAllActivations}>
          <Select 
            options={selectOptions}
            value={selectedOption}
            styles={selectStyles}
            isSearchable={false}
            onChange={(selected) => selectOnChange(selected)}
          />
        </SelectWrapper>
        <InputWrapper isAllActivations={isAllActivations}>
          <Input
            placeholder={inputPlaceholder}
            onChange={e => inputOnChange(e)}
          />
          <SearchIconWrapper>
            <Search />
          </SearchIconWrapper>
        </InputWrapper>
      </InputsWrapper>
    </Wrapper>
  )
}

export default MyApiTopNav

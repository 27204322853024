import React from 'react';
import {
  ModalContainer,
  ModalContent,
  ModalTitle,
  ModalText,
  ModalLink,
  ModalButtonWrapper,
  ModalButton
} from './styles';

// wrapper for modal windows
const ModalWrapper = ({isOpen, component}) => (
  <ModalContainer isOpen={isOpen}>
    {component}
  </ModalContainer>
)

export default ModalWrapper

// function for build modal with  content and modal wrapper
export const buildModal = (title, texts, linkText, linkClickAction, buttonText, buttonClickAction) => {
  return (
    <ModalContent>
      <ModalTitle>{title}</ModalTitle>
      {texts.map(text => <ModalText key={text + text.length}>{text}</ModalText>)}
      <ModalLink onClick={linkClickAction}>{linkText}</ModalLink>
      <ModalButtonWrapper>
        <ModalButton onClick={buttonClickAction}>{buttonText}</ModalButton>
      </ModalButtonWrapper>
    </ModalContent>
  )
}

const theme = {
  white: '#fff',
  black: '#4a4a4a',
  pink: {
    light: '#F6DFDF',
    med: '#FF6A8D',
    dark: '#D14E6F'
  }
}

export default theme

import styled from 'styled-components';
import { rem } from 'polished';

export const Wrapper = styled.div`
`

export const TopNavBar = styled.div`
  height: 116px;
  width: 100%;
  background-color: #F7E4E4;
  display: grid;
  grid-template-columns: 1fr 400px;
`

export const PageSectionWrapper = styled.div`
  font-size: ${rem(14)};
  text-transform: uppercase;
  padding-left: 70px;
  display: grid;
  grid-template-columns: 150px 1fr;
  align-items: center;
`

export const PageSection = styled.div`
  color: #939393;
`

export const PageSectionActivationTitle = styled.div`
  color: #000;
`

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 200px);
  align-items: center;
`

export const Button = styled.div`
  height: 37px;
  width: 165px;
  border: 1.5px solid #3C3C3C;
  border-radius: 19px;
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: ${rem(12)};
  color: #3C3C3C;
  text-transform: uppercase;
  padding-top: 3px;
  cursor: pointer;
`

export const ContentWrapper = styled.div`
  height: 910px;
  width: 1024px;
  margin: 50px auto;
`

export const ContentTitleWrapper = styled.div`
  height: 90px;
  width: 100%;
  border: 1.35px solid #EDEDED;
  background-color: #FAF9F9;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 400px;
  text-transform: uppercase;
  color: #000000;
  font-size: ${rem(13)};
`

export const CardsWrapper = styled.div`
  height: 770px;
  margin-top: 50px;
  display: grid;
  grid-template-columns: 450px 1fr 450px;
`

export const Card = styled.div`
  height: 770px;
  border: 1.35px solid #EDEDED;
  background-color: #FAF9F9;
`

export const CardTitle = styled.div`
  height: 58px;
  width: 100%;
  background-color: #D8D8D8;
  border: 0.9px solid #979797;
  text-transform: uppercase;
  font-size: ${rem(24)};
  color: #fff;
  display: grid;
  align-items: center;
  justify-content: center;
`

export const CardContent = styled.div`
  padding: 0px 30px;
  overflow-y: auto;
  max-height: 710px;
`

export const CardText = styled.div`
  margin-top: ${props => props.isFirst ? '40' : '20'}px;
  ${props => props.isBold ? 'font-weight: bold;' : ''}
  font-size: ${rem(12)};
  text-transform: uppercase;
`

export const ImageWrapper = styled.div`
  height: 270px;
  width: 100%;
  margin: 30px auto;
  background-color: #fff;
  border: 0.9px solid #191919;
  display: grid;
  align-items: center;
  justify-content: center;
`

export const Image = styled.img`
  max-height: 245px;
  max-width: 350px;
`

export const Description = styled.div`
  margin-top: 20px;
  font-size: ${rem(14)};
`

export const ChainWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
`


export const ProductsWrapper = styled.div`
  overflow-y: auto;
  max-height: 710px;
`

import styled from 'styled-components';
import { rem } from 'polished';
import theme from 'lib/styles/theme';

export const Wrapper = styled.div`
`

export const PageSection = styled.div`
  height: 100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: #f7e4e4;
`

export const PageSectionTitle = styled.div`
  display: grid;
  align-items: center;
  justify-content: left;
  color: #000;
  text-transform: uppercase;
  font-size: ${rem(14)};
  padding-left: 107px;
  font-family: "Helvetica-Bold";
  `

export const PageSectionButton = styled.div`
  display: grid;
  align-items: center;
  justify-content: right;
  padding-right: 35px;

  > a {
    height: 37px;
    width: 165px;
    border: 1.5px solid #3c3c3c;
    padding: 0 15px;
    padding-top: 1.5px;
    border-radius: 19px;
    color: #3c3c3c;
    text-transform: uppercase;
    text-decoration: none;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-column-gap: 15px;
    align-items: center;
    justify-items: start;

    > p {
      font-family: "Helvetica-Bold";
      font-size: 12px;
      line-height: 1;
    }

    > p.plus {
      font-size: 32px;
      font-family: inherit;
      position: relative;
      top: -3px;
    }
  }
`

export const SortSection = styled.div`
  height: 80px;
  width: calc(100% - 35px - 107px);
  margin: auto;
  margin-left: 107px;
  display: grid;
  grid-template-columns: 1fr 166px 426px;
  grid-column-gap: 90px;
  align-items: end;
  justify-content: left;
  font-family: "Helvetica-Bold";
`

export const SortColumn = styled.div`
  text-transform: uppercase;
  color: ${theme.black};
  font-size: ${rem(13)};
  cursor: pointer;

  ${props => props.isArrow ?
    `&::after {
      content: " ▼";
    }` :
    ''
  }
  ${props => props.isCentered ?
    'text-align: center;' :
    ''
  }
`

export const EmptyBrands = styled.div`
  height: calc(100vh - 610px);
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 10px;
  background-color: #fff;

  > p {
    color: #838383;
    text-align: center;
    font-size: 20px;

    > a {
      color: ${theme.pink.dark};
      text-transform: uppercase;
      text-decoration: underline;
      display: inline;
    }
  }

  > :nth-child(1) {
    display: grid;
    align-items: end;
  }
`

export const Table = styled.div``

export const Row = styled.div`
  height: 60px;
  border-top: 1.5px solid #ededed;

  &:last-of-type {
    border-bottom: 1.5px solid #ededed;
  }
  &:nth-child(odd) {
    background-color: #faf9f9;
  }
`

export const InfoMessage = styled.div`
  height: 80px;
  background-color: #ffb4b4;
  font-size: ${rem(20)};
  color: #884959;
  text-align: center;
  padding-top: 30px;

  > a {
    color: #884959;
  }
`

export const MessageWrapper = styled.div`
  > svg {
    position: relative;
    left: -20px;
    top: 2px;
  }
`

import { types, flow, getEnv } from 'mobx-state-tree';
import { Product } from 'data-access/stores/models/product';

export const mapProduct = (product, currentBrandName) => ({
  id: product.id,
  title: product.title,
  imageUrl: product.images.length ? product.images[0] : 'empty.png',
  price: product.price,
  brandName: product.brandName || currentBrandName || '',
  scheduleId: product.scheduleId,
  endDateTime: product.endDate ? new Date(product.endDate) : new Date()
})

export const ProductsStore = types.model('ProductsStore', {
  products: types.array(Product),
  isLoading: false
})
.actions(self => ({
  fetchProducts: flow(function* fetchProducts(requestParams, requestContinuationToken) {
    self.isLoading = true
    self.products = []

    const url = 'syndication/v1/products'
    let params = requestParams
    if (requestContinuationToken !== undefined) {
      params = `${requestParams}&continuationToken=${requestContinuationToken}`
    }

    let data
    try {
      data = yield getEnv(self).apiClient.get(url + params)
    } catch(e) {
      console.log(e)
      self.isLoading = false
      return undefined
    }
    const { continuationToken, products } = data.data

    self.products = products.map(product => mapProduct(product))

    let newContinuationToken = undefined
    if (continuationToken !== undefined) {
      params = `${requestParams}&continuationToken=${continuationToken}`
      let { data } = yield getEnv(self).apiClient.get(url + params)
      const nextContinuationToken = data.continuationToken
      const nextProducts = data.products
      if (nextContinuationToken !== undefined && nextProducts.length > 0) {
        newContinuationToken = continuationToken
      }
    }

    self.isLoading = false
    return newContinuationToken
  }),
}))

import React, { Component } from 'react';
import SliderLeftArrow from 'components/atoms/icons/slider-left-arrow';
import SliderRightArrow from 'components/atoms/icons/slider-right-arrow';
import CircledCross from 'components/atoms/icons/circled-cross';
import {
    Wrapper,
    ButtonsWrapper,
    Button,
    BrandsWrapper,
    BrandWrapper,
    BrandImage,
    BrandName,
    CrossWrapper,
    PaginationArrowWrapper
} from './styles';

// class for diplay selected brands on the top for the add brands page
class SelectedBrands extends Component {
  constructor(props) {
    super(props)

    this.brandsSize = 3

    this.state = {
      fromItem: 0
    }
  }

  changePage = delta => {
    this.setState(prevState => {
      return {
        fromItem: prevState.fromItem + delta
      }
    })
  }

  render() {
    const { brands, cancel, done, unselect } = this.props
    let { fromItem } = this.state

    if (fromItem > brands.length - this.brandsSize) {
      fromItem = brands.length - this.brandsSize
    }

    let currentBrands
    if (brands.length > this.brandsSize) {
      currentBrands = brands.slice(fromItem, fromItem + this.brandsSize)
    } else {
      currentBrands = brands
    }

    return (
      <Wrapper>
        <ButtonsWrapper>
          <Button onClick={() => cancel()}>cancel</Button>
          <Button onClick={() => done()}>done</Button>
        </ButtonsWrapper>

        <BrandsWrapper>
          {fromItem !== 0 && brands.length > this.brandsSize ?
            <PaginationArrowWrapper onClick={() => this.changePage(-1)}>
              <SliderLeftArrow />
            </PaginationArrowWrapper> :
            <PaginationArrowWrapper />
          }
          {currentBrands.map(brand => (
            <BrandWrapper key={brand.id}>
              <BrandImage src={brand.imageUrl} />
              <BrandName>{brand.name}</BrandName>
              <CrossWrapper onClick={() => unselect(brand)}>
                <CircledCross />
              </CrossWrapper>
            </BrandWrapper>
          ))}
          {fromItem < brands.length - this.brandsSize && brands.length > this.brandsSize ?
            <PaginationArrowWrapper onClick={() => this.changePage(1)}>
              <SliderRightArrow />
            </PaginationArrowWrapper> :
            <PaginationArrowWrapper />
          }
        </BrandsWrapper>
      </Wrapper>
    )
  }
}

export default SelectedBrands

import styled from 'styled-components';
import theme from 'lib/styles/theme';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: ${theme.pink.dark};
`

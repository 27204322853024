import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import GlobalStyles from 'lib/styles/global-styles';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Callback from 'components/containers/callback';
import PrivateRoute from 'components/containers/protected';
import Profile from 'components/organisms/profile';
import Brands from 'components/organisms/brands';
import AddBrands from 'components/organisms/add-brands';
import MyApi from 'components/organisms/my-api';
import VisibleProducts from 'components/organisms/visible-products';
import VisibleContent from 'components/organisms/visible-content';
import Activation from 'components/organisms/activation';
import Login from 'components/organisms/login';
import Logout from 'components/organisms/logout';
import '@shopify/polaris/dist/styles.css';
import "./index.css"

// App class for the syndication partner dashboard
class App extends Component {
  render() {
    const { authStore } = this.props.store
    return (
      <Fragment>
        <GlobalStyles />
        <Router>
          <Route
            path='/'
            component={() => <Redirect to='/brands' />}
            exact
          />
          <Route
            path='/login'
            component={Login}
            exact
          />
          <Route
            path="/callback"
            render={() => <Callback />}
            exact
          />
          <PrivateRoute
            path='/logout'
            component={Logout}
            authStore={authStore}
            exact
          />
          <PrivateRoute
            path='/profile'
            component={Profile}
            authStore={authStore}
            exact
          />
          <PrivateRoute
            path='/brands'
            component={Brands}
            authStore={authStore}
            exact
          />
          <PrivateRoute
            path='/add-brands'
            component={AddBrands}
            authStore={authStore}
            exact
          />
          <PrivateRoute
            path='/my-api'
            component={() => <Redirect to="/my-api/all" />}
            authStore={authStore}
            exact
          />
          <PrivateRoute
            path='/my-api/all'
            component={MyApi}
            authStore={authStore}
            exact
          />
          <PrivateRoute
            path='/my-api/visible-products'
            component={VisibleProducts}
            authStore={authStore}
            exact
          />
          <PrivateRoute
            path='/my-api/visible-content'
            component={VisibleContent}
            authStore={authStore}
            exact
          />
          <PrivateRoute
            path='/activation/:id'
            component={Activation}
            authStore={authStore}
            exact
          />
        </Router>
      </Fragment>
    )
  }
}

export default inject('store')(observer(App))

import { types } from 'mobx-state-tree';

export const Product = types.model('ProductModel', {
  id: types.string,
  title: types.string,
  imageUrl: types.string,
  price: types.number,
  brandName: types.string,
  scheduleId: types.string,
  endDateTime: types.Date
})

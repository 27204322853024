import styled from 'styled-components';
import theme from 'lib/styles/theme';

export const Wrapper = styled.div`
  height: 80px;
  width: 100%;
  background-color: ${theme.pink.dark};
  position: fixed;
  top: 0;
  display: grid;
  grid-template-columns: 252px 1fr 300px;
  grid-column-gap: 50px;
  align-items: center;
  z-index: 30;
`

export const LogoWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 61px 1fr;
  grid-column-gap: 20px;
  padding-left: 26px;

  > p {
    font-size: 18px;
    font-family: "Helvetica-Bold";
    color: #fff;
    text-transform: uppercase;
  }
`

export const NavLinksWrapper = styled.div`
  display: grid;
  justify-content: end;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 50px;

  > a {
    font-size: 14px;
    font-family: "Helvetica-Bold";
    text-transform: uppercase;
    text-decoration: none;
    color: ${theme.white};
    position: relative;

    &.active {
      &:before {
        position: absolute;
        left: 0;
        bottom: -5px;
        content: '';
        width: 100%;
        height: 2px;
        background-color: ${theme.white};
      }
    }
  }
`

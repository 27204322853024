import React, { Component, Fragment } from 'react';
import { Wrapper, NewWrapper, Link } from './styles';
import Loader from 'components/atoms/loader';

// function for render pagination section on every page where it needs
class Paginator extends Component {
  render() {
    const { page, pageSize, itemsCount, changePage, styles, isLoading } = this.props
    const hasPrev = page !== 0 ? true : false
    const hasNext = page * pageSize + pageSize < itemsCount ? true : false
    return (
      <Wrapper style={styles}>
        <div></div>
        {hasPrev ?
          <Fragment>
            <Link onClick={() => changePage(-1)}>&#60;</Link>
            <Link onClick={() => changePage(-1)}>{page}</Link>
          </Fragment> :
          <Fragment>
            <Link disabled />
            <Link disabled />
          </Fragment>
        }
        <Link disabled>{page + 1}</Link>
        {hasNext ?
          <Fragment>
            <Link onClick={() => changePage(1)}>{page + 2}</Link>
            <Link onClick={() => changePage(1)}>&#62;</Link>
          </Fragment> :
          <Fragment>
            <Link disabled />
            <Link disabled />
          </Fragment>
        }
        {isLoading ?
          <Loader
            size={'small'}
            text={''}
          /> :
          ''
        }
      </Wrapper>
    )
  }
}

export const NewPaginator = ({page, hasNext, hasPrev, changePage}) => {
  return (
    <NewWrapper>
      <div></div>
      {hasPrev ?
        <Fragment>
          <Link onClick={() => changePage(-1)}>&#60;</Link>
          <Link onClick={() => changePage(-1)}>{page}</Link>
        </Fragment> :
        <Fragment>
          <Link disabled />
          <Link disabled />
        </Fragment>
      }
      <Link disabled>{page + 1}</Link>
      {hasNext ?
        <Fragment>
          <Link onClick={() => changePage(1)}>{page + 2}</Link>
          <Link onClick={() => changePage(1)}>&#62;</Link>
        </Fragment> :
        <Fragment>
          <Link disabled />
          <Link disabled />
        </Fragment>
      }
    </NewWrapper>
  )
}

export default Paginator

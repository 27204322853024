import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Modal, { buildModal } from 'components/atoms/modal';
import Activation from 'components/molecules/activation';
import { NewPaginator } from 'components/molecules/paginator';
import MyApiTopNav from 'components/molecules/my-api-top-nav';
import { ApiLoader } from 'components/atoms/loader';
import {
  Wrapper,
  TitlesSection,
  Title,
  EmptyActivations,
  Table,
  Row
} from './styles';

const options = [
  {value: 'status', label: 'status'},
  {value: 'startDate', label: 'start date'},
  {value: 'brandName', label: 'brand'}
]

// class for render main component (wo top and bottom navs) in the all activations page
class MyApi extends Component {
  state = {
    selectedSorting: Object.create(options[0]),
    query: '',
    page: 0,
    pageSize: 10,
    activationToHide: null,
    continuationTokens: []
  }

  async updateInfo(state) {
    const { page, pageSize, selectedSorting, query, continuationTokens } = state
    let params = `?pageLimit=${pageSize}`
    params += `&scheduleType=all`
    params += `&sortedBy=${selectedSorting.value}`
    if (query) {
      params += `&q=${query}`
    }
    const continuationTokenIndex = page - 1
    const continuationToken = continuationTokens[continuationTokenIndex]
    const { fetchActivations } = this.props.store.activationsStore
    const newContinuationToken = await fetchActivations(params, continuationToken)
    if (newContinuationToken !== undefined) {
      this.setState({
        continuationTokens: [...continuationTokens.slice(0, page), newContinuationToken]
      })
    }
  }

  async componentDidMount() {
    await this.updateInfo(this.state)
  }

  async sortBy(selected) {
    const { selectedSorting } = this.state
    if (selectedSorting.value !== selected.value) {
      this.setState({
        selectedSorting: Object.create(selected),
        page: 0,
        continuationTokens: []
      }, function() {
        this.updateInfo(this.state)
      })
    }
  }

  async search(e) {
    this.setState({
      query: e.target.value,
      page: 0,
      continuationTokens: []
    }, function() {
      this.updateInfo(this.state)
    })
    e.preventDefault()
  }

  async makeVisible(activation) {
    const { changeStatus } = this.props.store.activationsStore
    changeStatus(activation.id)
    await this.updateInfo(this.state)
  }

  async confirmHide() {
    const { activationToHide } = this.state
    const { changeStatus } = this.props.store.activationsStore
    changeStatus(activationToHide.id)
    this.setState({
      activationToHide: null
    }, function() {
      this.updateInfo(this.state)
    })
  }

  formatDate = date => {
    let result = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear() + ' @ '
    let hours = date.getHours()
    result += hours > 12 ? (hours - 12) + ' PM' : hours + ' AM'
    return result 
  }

  changePage = delta => {
    this.setState((prevState) => {
      return {
        page: prevState.page + delta
      }
    }, function() {
      this.updateInfo(this.state)
    })
  }

  hide = activation => {
    this.setState({
      activationToHide: activation
    })
  }

  cancelHide = () => {
    this.setState({
      activationToHide: null
    })
  }

  render() {
    const { isLoading, activations } = this.props.store.activationsStore
    const { page, activationToHide, selectedSorting, continuationTokens } = this.state
    if (!selectedSorting.label.startsWith('sort by: ')) {
      selectedSorting.label = 'sort by: ' + selectedSorting.label
    }

    const hasNext = continuationTokens[page] !== undefined ? true : false
    const hasPrev = page > 0 ? true : false

    const modal = buildModal(
      'hide activation',
      [
        'Are you sure you’d like to hide this activation from your API feed?',
        'Doing so will switch its status to “hidden” and will remove all related products and content from being visible to end consumers.'
      ],
      'yes, hide this activation.',
      () => this.confirmHide(),
      'cancel',
      () => this.cancelHide()
    )

    return (
      <Wrapper>
        <Modal isOpen={activationToHide ? true : false} component={modal} />

        <MyApiTopNav
          selectOptions={options}
          selectedOption={selectedSorting}
          selectOnChange={(selected) => this.sortBy(selected)}
          inputPlaceholder={'search activations'}
          inputOnChange={e => this.search(e)}
          isAllActivations
        />

        <TitlesSection>
          <Title />
          <Title>title</Title>
          <Title>description</Title>
          <Title>products</Title>
          <Title>status</Title>
        </TitlesSection>

        {isLoading ?
          <ApiLoader /> :
          activations.length === 0 ?
          <EmptyActivations>You currently have no activations.</EmptyActivations> :
          <Fragment>
            <Table>
              {activations.map(activation => (
                <Row
                  key={activation.id}
                  status={activation.status}
                >
                  <Activation
                    key={activation.id}
                    id={activation.id}
                    imageUrl={activation.imageUrl}
                    brandName={activation.brandName}
                    title={activation.title}
                    description={activation.description}
                    products={activation.products}
                    status={activation.status}
                    startDateTime={this.formatDate(activation.startDateTime)}
                    endDateTime={this.formatDate(activation.endDateTime)}
                    unavailableProductsCount={activation.unavailableProductsCount}
                    makeVisible={() => this.makeVisible(activation)}
                    hide={() => this.hide(activation)}
                  />
                </Row>
              )
              )}
            </Table>

            <NewPaginator
              page={page}
              hasNext={hasNext}
              hasPrev={hasPrev}
              changePage={(delta) => this.changePage(delta)}
            />

          </Fragment>
        }
      </Wrapper>
    )
  }
}

export default inject('store')(observer(MyApi))

import React, { Component } from 'react';
import { LoginLayout } from './styles';
import Auth from 'lib/common/auth-service';

const authService = new Auth();

export class Login extends Component {
  componentDidMount() {
    authService.auth0.authorize()
  }

  render() {
    return <LoginLayout />
  }
}

export default Login

const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const helper = {
  isInIframe
};

export default helper;

import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { NewPaginator } from 'components/molecules/paginator';
import MyApiTopNav from 'components/molecules/my-api-top-nav';
import { ApiLoader } from 'components/atoms/loader';
import Content from 'components/molecules/content';
import {
  Wrapper,
  ContentsWrapper,
  EmptyContents
} from './styles';

const options = [
  {value: 'endDate', label: 'end date'},
  {value: 'name', label: 'by name'},
  {value: 'brandName', label: 'by brand'},
]

class VisibleContent extends Component {
  state = {
    page: 0,
    pageSize: 9,
    query: '',
    selectedSorting: Object.create(options[0]),
    continuationTokens: []
  }

  async updateInfo(state) {
    const { page, pageSize, selectedSorting, query, continuationTokens } = state
    let params = `?pageLimit=${pageSize}`
    params += `&contentOnly=true`
    params += `&sortedBy=${selectedSorting.value}`
    if (query) {
      params += `&q=${query}`
    }
    const continuationTokenIndex = page - 1
    const continuationToken = continuationTokens[continuationTokenIndex]
    const { fetchContents } = this.props.store.contentsStore
    const newContinuationToken = await fetchContents(params, continuationToken)
    if (newContinuationToken !== undefined) {
      this.setState({
        continuationTokens: [...continuationTokens.slice(0, page), newContinuationToken]
      })
    }
  }

  async componentDidMount() {
    await this.updateInfo(this.state)
  }

  async sortBy(selected) {
    const { selectedSorting } = this.state
    if (selectedSorting.value !== selected.value) {
      this.setState({
        selectedSorting: Object.create(selected),
        page: 0,
        continuationTokens: []
      }, function() {
        this.updateInfo(this.state)
      })
    }
  }

  async search(e) {
    this.setState({
      query: e.target.value,
      page: 0,
      continuationTokens: []
    }, function() {
      this.updateInfo(this.state)
    })
    e.preventDefault()
  }

  changePage = delta => {
    this.setState((prevState) => {
      return {
        page: prevState.page + delta
      }
    }, function() {
      this.updateInfo(this.state)
    })
  }

  formatDate = date => {
    let result = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear() + ' @ '
    let hours = date.getHours()
    result += hours > 12 ? (hours - 12) + ' PM' : hours + ' AM'
    return result 
  }

  render() {
    const { isLoading, contents } = this.props.store.contentsStore
    const { page, selectedSorting, continuationTokens } = this.state
    if (!selectedSorting.label.startsWith('sort by: ')) {
      selectedSorting.label = 'sort by: ' + selectedSorting.label
    }

    const hasNext = continuationTokens[page] !== undefined ? true : false
    const hasPrev = page > 0 ? true : false

    return (
      <Wrapper>
        <MyApiTopNav
          selectOptions={options}
          selectedOption={selectedSorting}
          selectOnChange={(selected) => this.sortBy(selected)}
          inputPlaceholder={'search contents'}
          inputOnChange={e => this.search(e)}
        />

        {isLoading ?
          <ApiLoader /> :
          contents.length === 0 ?
          <EmptyContents>
            <p>You currently have no visible content.</p>
            <p><NavLink to='/my-api'>Make activations visible</NavLink> to view related content here.</p>
          </EmptyContents> :
          <Fragment>
            <ContentsWrapper>
              {contents.map(content => (
                <Content
                  key={content.id}
                  imageUrl={content.imageUrl}
                  brandName={content.brandName}
                  title={content.title}
                  description={content.description}
                  scheduleId={content.scheduleId}
                  startDateTime={this.formatDate(content.startDateTime)}
                  endDateTime={this.formatDate(content.endDateTime)}
                />
              ))}
            </ContentsWrapper>

            <NewPaginator
              page={page}
              hasNext={hasNext}
              hasPrev={hasPrev}
              changePage={(delta) => this.changePage(delta)}
            />

          </Fragment>
        }
      </Wrapper>
    )
  }
}

export default inject('store')(observer(VisibleContent))

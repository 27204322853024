import React from 'react';

const CircledCross = ({ fill, height, width, styles }) => (
  <svg style={styles} width={width} height={height} viewBox={"0 0 " + width + " " + height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5731 14.7105L22.581 22.4436" stroke={fill} strokeWidth="1.7496" strokeLinecap="square"/>
    <path d="M14.356 22.6753L22.5255 14.2155" stroke={fill} strokeWidth="1.7496" strokeLinecap="square"/>
    <rect x="1.77964" y="18.7919" width="23.65" height="23.65" rx="11.825" transform="rotate(-46 1.77964 18.7919)" stroke="#FF7293" strokeWidth="1.35"/>
  </svg>

);

CircledCross.defaultProps = {
  fill: '#FF7293',
  height: '37',
  width: '37',
  styles: {
    cursor: 'pointer'
  }
};

export default CircledCross;

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import AvatarUploader from "components/atoms/avatar-uploader";
import { PageWrapper } from './styles';
import {
  AppProvider,
  Page,
  Layout,
  DisplayText,
  Button,
  Frame,
  Toast,
  Card,
  TextField
} from "@shopify/polaris"

// class for render main component (wo top and bottom navs) in the profile page
class Profile extends Component {
  state = {
    profile: {
      company: this.props.store.authStore.user.companyName,
      fullName: this.props.store.authStore.user.contactName,
      phone: this.props.store.authStore.user.phone
    },
    isUpdatedProfile: false
  }

  async componentDidMount() {
    document.documentElement.style.fontSize = "62.5%"
    const { fetchUser } = this.props.store.authStore
    await fetchUser()
    this.setState({
      profile: {
        company: this.props.store.authStore.user.companyName,
        fullName: this.props.store.authStore.user.contactName,
        phone: this.props.store.authStore.user.phone
      }
    })
  }

  componentWillUnmount = () => {
    document.documentElement.style.fontSize = "unset"
  }

  handleChangeProfileField = (name, value) => this.setState({ profile: { ...this.state.profile, [name]: value } })

  handleClickSave = async () => {
    const { updateUser } = this.props.store.authStore
    await updateUser(this.state.profile)
    this.setState({ isUpdatedProfile: true })
  }

  handleClickDismissSaveToast = () => this.setState({ isUpdatedProfile: false })

  render() {
    const { user } = this.props.store.authStore
    const { profile, isUpdatedProfile } = this.state

    return (
      <AppProvider>
        <PageWrapper>
          <Page>
            <Frame>
              <Layout>
                <Layout.Section oneHalf>
                  <DisplayText>Update your profile image</DisplayText>
                  <AvatarUploader />
                </Layout.Section>

                <Layout.Section oneHalf>
                  <Card title="Company and Contact">
                    <Card.Section>
                      <TextField
                        label="Email"
                        value={user.email}
                        disabled={true}
                      />
                    </Card.Section>
                    <Card.Section>
                      <TextField
                        label="Company Website"
                        value={user.companyWebsite}
                        disabled={true}
                      />
                    </Card.Section>
                    <Card.Section>
                      <TextField
                        label="Company Name"
                        value={profile.company}
                        onChange={value => this.handleChangeProfileField("company", value)}
                      />
                    </Card.Section>
                    <Card.Section>
                      <TextField
                        label="Contact Name"
                        value={profile.fullName}
                        onChange={value => this.handleChangeProfileField("fullName", value)}
                      />
                    </Card.Section>
                    <Card.Section>
                      <TextField
                        label="Phone"
                        value={profile.phone}
                        onChange={value => this.handleChangeProfileField("phone", value)}
                      />
                    </Card.Section>
                  </Card>

                  <div style={{margin: "1.6rem 0"}}>
                    <Button onClick={this.handleClickSave}>Save</Button>
                  </div>
                </Layout.Section>
              </Layout>

              { isUpdatedProfile && (
                <Toast
                  content="Your profile has been saved"
                  onDismiss={this.handleClickDismissSaveToast}
                />
              ) }
            </Frame>
          </Page>
        </PageWrapper>
      </AppProvider>
    )
  }
}

export default inject('store')(observer(Profile))

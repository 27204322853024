import React from 'react';

const SliderRightArrow = ({ fill, height, width, styles }) => (
  <svg style={styles} width={width} height={height} viewBox={"0 0 " + width + " " + height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.28571 4.36364L17.3975 18.2701" stroke={fill} strokeWidth="5" strokeLinecap="square"/>
    <path d="M4.28571 31.8312L18.4869 18.921" stroke={fill} strokeWidth="5" strokeLinecap="square"/>
  </svg>
);

SliderRightArrow.defaultProps = {
  fill: '#fff',
  height: '35',
  width: '35',
  styles: {
    marginLeft: '15px',
    cursor: 'pointer'
  }
};

export default SliderRightArrow;

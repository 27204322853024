import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import Avatar from 'components/atoms/avatar';
import {
  UserDropdownContainer,
  DropdownSelector,
  CaretIcon,
  UserDropdownMenu
} from './styles';

// class for render userdropdown menu in the top navigation bar
class UserDropdown extends Component {
  state = {
    isOpen: false
  }

  handleLogOut = () => {
    const { history } = this.props
    history.replace('/logout')
  }

  handleToggle = () => {
    this.setState({isOpen: !this.state.isOpen})
  }

  render() {
    const { user } = this.props.store.authStore
    const { isOpen } = this.state
    const urlAvatar = user.avatarUrl ? user.avatarUrl : process.env.PUBLIC_URL + '/images/profile_avatar.png'

    return (
      <UserDropdownContainer>
        <DropdownSelector onClick={this.handleToggle} pointsUp={isOpen}>
          <Avatar url={urlAvatar} />
          <h3>{user.companyName}</h3>
          <CaretIcon  fill='white' size='2rem' />
        </DropdownSelector>
        {isOpen && (
          <UserDropdownMenu>
            <NavLink to='/profile'>account settings</NavLink>
            <div onClick={this.handleLogOut}>log out</div>
          </UserDropdownMenu>
        )}
      </UserDropdownContainer>
    )
  }
}

export default withRouter(inject('store')(observer(UserDropdown)))

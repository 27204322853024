import React from 'react';

export const Search = ({ fill, height }) => (
  <svg width={height} height={height} viewBox={"0 0 " + height + " " + height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16Z" stroke={fill} strokeWidth="2"/>
    <path d="M14.5 14.5L18.5 18.5" stroke={fill} strokeWidth="2" strokeLinecap="square"/>
  </svg>
);

Search.defaultProps = {
  fill: '#3C3C3C',
  height: '20'
};

export default Search;

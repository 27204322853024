import React from 'react';
import styled from 'styled-components';
import Spinner from 'reactjs-simple-spinner';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
`

const Loader = ({text='Loading...', size='large', styles}) => {
  return (
    <Wrapper style={styles}>
      <Spinner size={size} message={text} />
    </Wrapper>
  )
}

const ApiWrapper = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  margin: 100px auto;
`

export const ApiLoader = ({text='Loading...', size='large', styles}) => {
  return (
    <ApiWrapper style={styles}>
      <Spinner size={size} message={text} />
    </ApiWrapper>
  )
}

export default Loader
